import React, { useRef, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import styles from "./ProfilePage.module.css";
import "./filepond.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import LanCountdown from "../../../components/countdown/LanCountdown";
import Countdown from "react-countdown";
import QRCode from "react-qr-code";
import Axios from "axios";
import { APIURL } from "../../../constants";
import { Slide, Snackbar, Alert } from "@mui/material";
import useStore from "../../../store/userStore";
import GreetMessage from "../../../components/GreetMessage/GreetMessage";

registerPlugin(FilePondPluginFileValidateType);

const technicalTestsStart = new Date("2024-10-08T00:00:00");
const technicalTestsEnd = new Date("2024-10-13T00:00:00");

function ProfilePage() {
  const [user, setUser] = useStore((state) => [state.user, state.setUser]);

  const [CV, setCV] = useState(null);
  const [openToast, setOpenToast] = useState(false);

  const toastObj = useRef({
    type: "info",
    message: "",
  });

  const contentArray = [
    {
      title: "Job Hub",
      eventDate: "10 octombrie",
      signUpDates: "",
    },
    {
      title: "Career Launch",
      eventDate: "13 octombrie",
      signUpDates: [technicalTestsStart, technicalTestsEnd],
    },

    {
      title: "Hackathon Adobe",
      eventDate: "19 octombrie",
      signUpDates: "",
    },

    {
      title: "Workshop",
      eventDate: "29 octombrie",
      signUpDates: "",
    },
    {
      title: "LAN Party",
      eventDate: "16 noiembrie",
      signUpDates: "",
    },
  ];

  const calculateTimeCL = (startDate, endDate) => {
    const startTime = new Date(startDate).getTime();

    const now = new Date().getTime();

    const endTime = new Date(endDate).getTime();
    let timeDifference;
    if (startTime >= now) {
      timeDifference = startTime - now;
    } else {
      timeDifference = endTime - now;
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    return startDate
      ? startTime >= now
        ? `Au mai rămas ${days} zile, ${hours} ore și ${minutes} minute până la testele tehnice.`
        : endTime >= now
        ? `Testele tehnice se termină în ${days} zile, ${hours} ore și ${minutes} minute`
        : `Testele tehnice s-au terminat`
      : "";
  };

  function updateCV() {
    const payload = new FormData();
    payload.append(`${user.id}_${user.firstName}_${user.lastName}`, CV);
    const updatedUser = {
      ...user,
      cvIsUploaded: true,
    };
    console.log(updatedUser);
    Axios.put(`${APIURL}/user/${user.id}`, updatedUser)
    .then((response) => {
      console.log(response, "response");
      setUser(response.data);
    }).catch((error) => {
      console.log(error);
    });
    
    Axios.post(`${APIURL}/cvs`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(() => {
        toastObj.current.type = "success";
        toastObj.current.message = "CV-ul tău a fost schimbat cu succes";
      })
      .catch((error) => {
        console.log(error);

        toastObj.current.type = "error";
        toastObj.current.message = "Eroare de server la schimbarea CV-ului";
      })
      .finally(() => {
        setCV(null);
        setOpenToast(true);
      });
  }

  return (
    <>
      <GreetMessage />
      <div className={styles.profileContainer}>
        <div className={styles.profileCentralContainer}>
          <div className={styles.lowerSectionContainer}>
            <div className={styles.profileFirstCentralContainer}>
              <div style={{ width: "70%" }}>
                <div className={styles.containerBtnDownloadCV}>
                  <div className={styles.wrapperBtnDownloadCV}>
                    <a href={`${APIURL}/cvs/${user.id}`} download>
                      <button disabled={user.cvIsUploaded !== null ? false : true} className={ user.cvIsUploaded !== null ? styles.btnDescarcaCV : styles.btnNuDescarcaCV}>
                        DESCARCĂ-ȚI CV-UL
                      </button>
                    </a>

                    <FilePond
                      files={CV}
                      name={`${user.firstName}_${user.lastName}`}
                      acceptedFileTypes={"application/pdf"}
                      labelIdle={user.cvIsUploaded !== null ? `<p id=${styles.uploadCV}>SCHIMBĂ-ȚI CV-UL</p>` : `<p id=${styles.uploadCV}>ADAUGA-ȚI CV-UL</p>`}
                      onupdatefiles={(fileItems) => {
                        if (fileItems && fileItems[0]) {
                          setCV(fileItems[0].file);

                        }
                      }}
                      credits={false}
                      dropOnPage
                      dropValidation
                      className={styles.changeCV}
                      id={`${user.id}_${user.firstName}_${user.lastName}`}
                    />

                    {CV && (
                      <div className={styles.containerSaveCVBtn}>
                        <button onClick={updateCV}>Salvează CV</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.profileCareerLaunchTimer}>
                <LanCountdown />
              </div>
            </div>
            <div className={styles.eventsContainer}>
              {contentArray.map((event, count) => {
                count++;
                if (count % 2 === 0)
                  return (
                    <div className={styles.eventContainer} key={event.title}>
                      <div className={styles.contentEvent}>
                        <span className={styles.paragSpan}>
                          <p>
                            {event.title} <br />
                            {window.matchMedia("(min-width: 912px)").matches && (
                              <Countdown
                                className={styles.paragDate}
                                date={Date.now()}
                                renderer={calculateTimeCL.bind(
                                  null,
                                  event.signUpDates[0],
                                  event.signUpDates[1]
                                )}
                              />
                            )}
                          </p>
                        </span>
                        <p>{event.eventDate}</p>
                      </div>
                    </div>
                  );
                return (
                  <div
                    className={styles.eventBorderContainer}
                    key={event.title}
                  >
                    <div className={styles.contentEvent}>
                      <span className={styles.paragSpan}>
                        <p>
                          {event.title} <br />
                        </p>
                      </span>
                      <p>{event.eventDate}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* Am comentat QR code-ul pana il testam si il facem sa mearga */}

          <div className={styles.profileRightContainer}>
            <div
              style={{ color: "black", fontSize: "1.8rem", fontWeight: "600" }}
            >
              Codul tău QR
            </div>
            <div style={{ background: "white", padding: "16px" }}>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={`${user.id}`}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 64,
            width: "100%",
          }}
        ></div>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openToast}
          autoHideDuration={3000}
          onClose={() => setOpenToast(false)}
          TransitionComponent={Slide}
        >
          <Alert severity={toastObj.current.type}>
            {toastObj.current.message}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default ProfilePage;
