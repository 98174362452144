import { useRef, useState } from "react";
import ForgotPasswordContainer from "../../../components/form/ForgotPasswordContainer";
import { Alert, Button, Slide, Snackbar, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import styles from "./ForgotPassword.module.css";
import InputError from "../../../components/inputError/InputError";
import Axios from "axios";
import { APIURL } from "../../../constants";

function ForgotPassword() {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: "",
    },
  });
  const [openToast, setOpenToast] = useState(false);
  const toastObj = useRef({
    type: "info",
    message: "",
  });
  function onSubmit(formData) {
    console.log(formData);
    Axios.post(`${APIURL}/user/resetpass`, formData, {
      withCredentials: true,
    })
      .then((response) => {
        console.log(response);
        toastObj.current.type = "success";
        toastObj.current.message =
          "Verifica-ți emailul pentru a reseta parola.";
      })
      .catch((error) => {
        console.log(error);
        toastObj.current.type = "error";
        toastObj.current.message = error.response.data;
      })
      .finally(() => {
        setOpenToast(true);
      });
  }

  return (
    <div className={styles.forgotContainer}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        TransitionComponent={Slide}
      >
        <Alert severity={toastObj.current.type}>
          {toastObj.current.message}
        </Alert>
      </Snackbar>
      <ForgotPasswordContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.fieldContainer}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: {
                  value: true,
                  message: "Emailul trebuie completat",
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Email invalid",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  name="email"
                  label="Email"
                  variant="standard"
                  error={errors.email ? true : false}
                  sx={{
                    input: {
                      color: "white",
                      minWidth: "200px",
                      width: "30vw",
                      maxWidth: "280px",
                      paddingRight: "35px",
                    },
                    "& .MuiInput-underline:before, & .MuiInput-underline:after":
                      {
                        borderBottomColor: "#399345",
                      },
                  }}
                />
              )}
            ></Controller>
            <InputError errorObj={errors} field="email" />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#399345",
                color: "white",
                borderRadius: "1rem",
                marginTop: "3vh",
                padding: "10px 40px",
                "&:hover": {
                  backgroundColor: "var(--lime-green)",
                },
              }}
            >
              Trimite
            </Button>
          </div>
        </form>
      </ForgotPasswordContainer>
    </div>
  );
}

export default ForgotPassword;
