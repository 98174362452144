import "./App.css";

import { Outlet, ScrollRestoration } from "react-router-dom";


import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#1a9a32',
    },
    secondary: {
      main: '#b6e29f',
    },
    background: {
      default: '#000000',
      paper: '#3d3d3d',
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255,255,255,0.86)',
      hint: '#ffffff',
      disabled: 'rgba(255,255,255,0.7)',
    },
    success: {
      main: '#399345',
    },
  },
});

function App() {
  return (
    <div className="mainContainer">  
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Outlet />   
      </ThemeProvider>
      <ScrollRestoration />
    </div>
  );
}

export default App;
