export const jobHubDescription = (
  <div class="c1 doc-content">
  <p class="c0">
  &emsp; Îți dorești un job și nu știi exact de unde să începi procesul? ITFest sare în ajutorul tău! Vino la Job Hub, târgul de joburi dedicat studenților, pentru a afla mai multe detalii despre oportunitățile de angajare existente în cadrul companiilor partenere. 
  </p>
  <p class="c0">
  &emsp; Te așteptăm pe data de 10 octombrie între <b style={{color: "white"}}>orele 10:30 și 16:30 la DORO 16</b>, pentru a te lăsa descoperIT!
  </p>
</div>
);
