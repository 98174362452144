import React, { useCallback, useRef, useState } from 'react'

import styles from "./Details.module.css"
import { Button } from '@mui/material'
import CustomModal from '../modal/CustomModal'

function Details({ details }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <div>
      <Button
      onClick={openModal}
      variant="contained" 
      sx={{
        width: "130px",
        backgroundColor: "#074A29",
        borderRadius: "1.5rem",
        '&:hover': {
          backgroundColor: "#0E7E42",
        },
      }}>Detalii</Button>

      <CustomModal onClose={closeModal} isOpen={isModalOpen} title={details.title}>
        <div className={styles.detailsContent}>{details.description}</div>
      </CustomModal>
    </div>
  )
}

export default Details