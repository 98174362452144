import styles from "./ForgotPasswordContainer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function ForgotPasswordContainer({ children }) {
  return (
    <>
      <div className={styles.passwordContainerBorder}>
        <div className={styles.passwordContainer}>
          <div className={styles.passwordElements}>
            <Link to="/">
              <FontAwesomeIcon
                className={styles.goBack}
                icon={faChevronLeft}
              />
            </Link>

            <div className={styles.title}>Ai uitat parola?</div>
            <p>
              Introdu adresa de email pentru a primi un link de resetare a
              parolei.
            </p>
          </div>
          {children}
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordContainer;
