import React, { useCallback, useState } from 'react'

import styles from "./Rules.module.css"
import { Button } from '@mui/material'
import CustomModal from '../modal/CustomModal'

function Rules({ rules }) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <Button
        onClick={openModal}
        variant="contained"
        sx={{
          width: "130px",
          backgroundColor: "#074A29",
          borderRadius: "1.5rem",
          '&:hover': {
            backgroundColor: "#0E7E42",
          },
        }}>Regulament</Button>

      <CustomModal onClose={closeModal} isOpen={isModalOpen} title={rules.title}>
        <div className={styles.rulesContent}>{rules.text}</div>
      </CustomModal>
    </>
  )
}

export default Rules