import React from 'react'

import styles from "./LinkTo.module.css"

import { Link, useMatch } from "react-router-dom";


function LinkTo({pageTitle, link}) {
  const match = useMatch(link);

  const isLinkActiveStyle = match ? styles.eventOnClick : ""

  return (
    <div className={`${styles.eventContainer} ${isLinkActiveStyle}`}>
      <Link to={link} preventScrollReset={false} target={link === "https://itfest.sisc.ro/" ? "_blank" : "_self"} >
        {pageTitle}
      </Link>
    </div>
  )
}

export default LinkTo