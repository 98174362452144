import { Button } from "@mui/material";
import React, { useState } from "react";
import styles from "./AddMemberForm.module.css";

const AddMemberForm = ({ onSave }) => {
  const [id, setId] = useState("");

  const handleSave = () => {
    onSave(id);
    setId("");
  };

  return (
    <div className={styles.formContainer}>
      <input className={styles.input}
        type="number"
        placeholder="ID"
        value={id}
        onChange={(e) => setId(e.target.value)}
      />
      <Button
        onClick={handleSave}
        sx={{
          backgroundColor: "#ffad4a",
          color: "black",
          marginLeft: "10px",
          padding: "5px 15px",
          "&:hover": {
            backgroundColor: "#f2911b",
          },
        }}
      >
        Adaugă
      </Button>
    </div>
  );
};

export default AddMemberForm;
