import React from "react";

import styles from "./FormContainer.module.css";


function FormContainer({ children, linkUrl }) {
  const message = linkUrl === "/" ? "Sign up" : "Login";

  return (
    <div>
      <div className={styles.formContainerBorder}>
        <div className={styles.formContainer}>
          <img src="/icons/siglaITFest.svg" alt="logo ITFest" height={50} />
          <div className={styles.formHeader}>
            <h1 className={styles.headerMain}>{message}</h1>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default FormContainer;
