import React, { useRef } from 'react'
import CompanyCard from './CompanyCard'
import ToTechnicalTest from './actions/ToTechnicalTest'
import { useDrag, useDrop } from 'react-dnd'

function CLCard({ clCompanyData, moveCLCard, index, isAdobe }) {
    const cardRef = useRef(null)
    
      const [{ handlerID }, drop] = useDrop(() => ({
        accept: "CareerLaunchCard",
        collect(monitor){
          return {
            handlerID: monitor.getHandlerId()
          }
        },
        hover(clCardItem){
          const dragIndex = clCardItem.index
          const hoverIndex = index
    
          // nu dam drag & drop unui item unde este deja
          if (dragIndex === hoverIndex) {
            return
          }
    
          moveCLCard(dragIndex, hoverIndex)
          clCardItem.index = hoverIndex
        }
      }))
    
      const [{ isDragging }, drag] = useDrag(() => ({
        type: "CareerLaunchCard",
        item: () => {
          return { id: clCompanyData.id, index }
        },
        collect(monitor){
          return { isDragging: monitor.isDragging() }
        }
      }))

    drag(drop(cardRef))

  return (
    <div ref={cardRef} style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "grab"
    }}
    data-handler-id={handlerID}
    >
        <CompanyCard eventData={clCompanyData} movable={true}/>
        <ToTechnicalTest testLink={clCompanyData.linkTest} companyName={clCompanyData.companyName} period={clCompanyData.period} disable={isAdobe} />
    </div>
  )
}

export default CLCard