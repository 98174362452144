export const hackathonDescription = (
  <div class="c8 doc-content">
    <p dir="ltr">
    &emsp; Dacă ești pasionat de programare și vrei să-ți testezi limitele, ITFest este
      ocazia perfectă! În cadrul acestui maraton de programare, vei colabora cu
      echipa ta pentru a dezvolta o aplicație pe o temă aleasă de companiile
      partenere.
  </p>
  <p dir="ltr">
  &emsp; Pe 19 octombrie și 2 noiembrie, vor avea loc cele două Hackathoane, fiecare
      cu o tematică distinctă. Evenimentele se vor desfășura în format fizic, la
      sediul companiilor sau online. Te poți alătura uneia dintre ele, împreună cu
      o echipă formată din 2 până la 4 membri, cele mai bune aplicații vor fi
      premiate. Este momentul să accepți provocarea și să-ți demonstrezi talentul!
  </p>
  <div>
      <br/>
  </div>
  </div>
);
