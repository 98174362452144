import React, { useState } from "react";
import styles from "./FormInput.module.css";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";

function FormInput({ inputName, rules, placeholderText }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={inputName}
      rules={rules}
      render={({ field }) => {
        return (
          <div>
            <TextField
              {...field}
              label={placeholderText}
              variant="outlined"
              error={errors[inputName] ? true : false}
              fullWidth
              InputLabelProps={{
                style: {
                  color: "#399345",
                },
              }}
              sx={{
                input: {
                  color: "#399345",
                },
                "& .MuiOutlinedInput-root": {
                  width: "100%",
                  "& fieldset": {
                    borderColor: "#399345",
                  },
                },
              }}
            />
            <p className={styles.inputError}>
              {errors[inputName] && errors[inputName].message}
            </p>
          </div>
        );
      }}
    />
  );
}

export default FormInput;
