import { Alert, Button, Slide, Snackbar, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import InputError from "../../../components/inputError/InputError";
import styles from "./ResetPassword.module.css";
import { useRef, useState } from "react";
import Axios from "axios";
import ResetPasswordContainer from "../../../components/form/ResetPasswordContainer";
import { useNavigate, useParams } from "react-router-dom";
import { APIURL } from "../../../constants";

function ResetPassword() {
  const navigate = useNavigate();
  const { resetToken } = useParams();
  const [visisblePassword, setVisisiblePassword] = useState(false);
  const currentEyeIcon = visisblePassword
    ? "/icons/eye-slash-icon.svg"
    : "/icons/eye-icon.svg";
  const currentEyeAlt = visisblePassword ? "hide-password" : "show-password";
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      password1: "",
      password2: "",
    },
  });
  const [openToast, setOpenToast] = useState(false);
  const toastObj = useRef({
    type: "info",
    message: "",
  });

  function onSubmit(formData) {
    if (formData.password1 !== formData.password2) {
      toastObj.current = {
        type: "error",
        message: "Parolele nu coincid",
      };
      setOpenToast(true);
      return;
    }
    const data = { password: formData.password1 };
    Axios.post(`${APIURL}/user/resetPassword/${resetToken}`, data)
      .then((response) => {
        toastObj.current = {
          type: "success",
          message: response.data,
        };
        setOpenToast(true);
        setTimeout(() => {
            navigate("/");
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        toastObj.current = {
          type: "error",
          message: error.response.data.message,
        };
        setOpenToast(true);
      });
    console.log(formData);
  }

  return (
    <div className={styles.forgotContainer}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        TransitionComponent={Slide}
      >
        <Alert severity={toastObj.current.type}>
          {toastObj.current.message}
        </Alert>
      </Snackbar>
      <ResetPasswordContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.fieldContainer}>
            <Controller
              control={control}
              name="password1"
              rules={{
                required: {
                  value: true,
                  message: "Parola trebuie completată",
                },
                minLength: {
                  value: 8,
                  message: "Parola trebuie să aibă minim 8 caractere",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type={visisblePassword ? "text" : "password"}
                  label="Password"
                  variant="standard"
                  error={errors.password ? true : false}
                  InputLabelProps={{
                    style: {
                      color: "#399345",
                    },
                  }}
                  sx={{
                    input: {
                      color: "#399345",
                      minWidth: "200px",
                      width: "30vw",
                      maxWidth: "280px",
                      paddingRight: "35px",
                    },
                    "& .MuiInput-underline:before, & .MuiInput-underline:after":
                      {
                        borderBottomColor: "#399345",
                      },
                  }}
                />
              )}
            />
            <img
              onClick={() => setVisisiblePassword((prevState) => !prevState)}
              src={currentEyeIcon}
              alt={currentEyeAlt}
              height="30px"
              className={styles.eyeIcon}
            />
            <InputError errorObj={errors} field="password1" />
            <Controller
              control={control}
              name="password2"
              rules={{
                required: {
                  value: true,
                  message: "Parola trebuie completată",
                },
                minLength: {
                  value: 8,
                  message: "Parola trebuie să aibă minim 8 caractere",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type={visisblePassword ? "text" : "password"}
                  label="Password"
                  variant="standard"
                  error={errors.password ? true : false}
                  InputLabelProps={{
                    style: {
                      color: "#399345",
                    },
                  }}
                  sx={{
                    input: {
                      color: "#399345",
                      minWidth: "200px",
                      width: "30vw",
                      maxWidth: "280px",
                      paddingRight: "35px",
                    },
                    "& .MuiInput-underline:before, & .MuiInput-underline:after":
                      {
                        borderBottomColor: "#399345",
                      },
                  }}
                />
              )}
            />
            <img
              onClick={() => setVisisiblePassword((prevState) => !prevState)}
              src={currentEyeIcon}
              alt={currentEyeAlt}
              height="30px"
              className={styles.eyeIcon}
            />
          </div>
          <InputError errorObj={errors} field="password2" />
          <div className={styles.buttonContainer}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#399345",
                color: "white",
                borderRadius: "1rem",
                marginTop: "3vh",
                padding: "10px 40px",
                "&:hover": {
                  backgroundColor: "var(--lime-green)",
                },
              }}
            >
              Trimite
            </Button>
          </div>
        </form>
      </ResetPasswordContainer>
    </div>
  );
}

export default ResetPassword;
