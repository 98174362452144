import React, { useRef, useState } from "react";
import styles from "./JobHubPage.module.css";
import { jobHubDescription } from "../../../staticData/general/jobhubDescription";
import { Link } from "react-router-dom";
import { jobHubCompanies } from "../../../staticData/jobHub/jobHubData";
import CompanyCard from "../../../components/card/CompanyCard";
import { Alert, Button, Slide, Snackbar } from "@mui/material";
import useStore from "../../../store/userStore";
import Axios from "axios";
import { APIURL } from "../../../constants";

const jobHubID = 1;
const jobHubEnd = new Date("2024-10-11T00:00:00");

function JobHubPage() {
  const [user, eventsAttended, addEventAttended] = useStore((state) => [
    state.user,
    state.eventsAttended,
    state.addEventAttended,
  ]);

  const [openToast, setOpenToast] = useState(false);
  const toastObj = useRef({
    type: "error",
    message: "",
  });

  function jobHubSignUp() {
    Axios.post(`${APIURL}/attendance/${user.id}`, {
      eventId: jobHubID,
    })
      .then((res) => {
        toastObj.current.type = "success";
        toastObj.current.message = res.data;
        setOpenToast(true);
        addEventAttended(jobHubID);
      })
      .catch((error) => {
        toastObj.current.message = "Eroare de server";
        setOpenToast(true);

        console.log(error);
      });
  }

  return (
    <div>
      <h1 className={styles.title}>Job Hub</h1>
      <div className={styles.eventsDescriere}>{jobHubDescription}</div>
      <p className={styles.location}>
        Locație:{" "}
        <Link
          to="https://maps.app.goo.gl/37hgpEvC8rvXwuRJ7"
          target="_blank"
          style={{
            textDecoration: "underline",
          }}
        >
          DORO 16
        </Link>
      </p>
      <div className={styles.buttonContainer}>
        {new Date() < jobHubEnd &&
          (eventsAttended[jobHubID] ? (
            <p className={styles.signedUp}>Ai fost înscris că participi la eveniment</p>
          ) : (
            <Button
              onClick={jobHubSignUp}
              variant="contained"
              sx={{
                backgroundColor: "#1A9A32",
                borderRadius: "1.5rem",
                "&:hover": {
                  backgroundColor: "#136B1E",
                },
              }}
            >
              Sunt interesat
            </Button>
          ))}
      </div>
      <div className={styles.cardsContainer}>
        {jobHubCompanies.map((jobHubCompanyData, index) => {
          return <CompanyCard key={index} eventData={jobHubCompanyData} />;
        })}
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        TransitionComponent={Slide}
      >
        <Alert severity={toastObj.current.type}>
          {toastObj.current.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default JobHubPage;
