
import useStore from "../../store/userStore";

function GreetMessage() {
  const user = useStore((state) => state.user);

  return (
    <h2>
      <span style={{color:"var(--pistachio-green)"}}>Bine ai venit, </span><span>{user.firstName}!</span>
      <br />
      <span style={{color:"var(--pistachio-green)"}}>Id-ul Utilizator: </span><span>{user.id}</span>
    </h2>
  );
}
export default GreetMessage;
