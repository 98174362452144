import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./CareerLaunchPage.module.css";
import CustomModal from "../../../components/modal/CustomModal";
import { careerLaunchDescription } from "../../../staticData/general/careerlaunchDescription";
import { clAdobe } from "../../../staticData/careerLaunch/adobeCL";
import { clVeridion } from "../../../staticData/careerLaunch/veridionCL";
import useStore from "../../../store/userStore";
import EventStatus from "../../../components/eventStatus/EventStatus";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import CLCard from "../../../components/card/CLCard";
import { Alert, Button, Slide, Snackbar } from "@mui/material";
import Axios from "axios";
import { APIURL } from "../../../constants";

const technicalTestsEnd = new Date("2024-10-13T00:00:00");
const technicalTestsStart = new Date("2024-10-08T00:00:00");
const careerLaunchCompanies = [clAdobe, clVeridion];

function CareerLaunchPage() {
  const user = useStore((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const [openToast, setOpenToast] = useState(false);
  const toastObj = useRef({
    type: "info",
    message: "",
  });

  const prevCL = useRef([]);
  const [careerLaunchCards, setCareerLaunchCards] = useState([]);

  useEffect(() => {
    Axios.get(`${APIURL}/preference/${user.id}`, {
      withCredentials: true,
    })
      .then((prefArr) => {
        prefArr = prefArr.data;

        if (prefArr.length === 0) {
          setCareerLaunchCards(careerLaunchCompanies);
        } else {
          let preferencesDB = [];

          prefArr.sort((a, b) => a.preference - b.preference);

          for (let i = 0; i < careerLaunchCompanies.length; i++) {
            for (let j = 0; j < careerLaunchCompanies.length; j++) {
              if (prefArr[i].eventId === careerLaunchCompanies[j].id) {
                preferencesDB.push(careerLaunchCompanies[j]);
                break;
              }
            }
          }

          prevCL.current = preferencesDB;
          setCareerLaunchCards(preferencesDB);
        }
      })
      .catch((error) => {
        console.log(error);

        toastObj.current.type = "error";
        toastObj.current.message = "Eroare la primirea preferințelor salvate";

        setCareerLaunchCards(careerLaunchCompanies);
        setOpenToast(true);
      });
  }, [user.id]);

  function moveCLCard(dragIndex, hoverIndex) {
    setCareerLaunchCards((prevCards) => {
      const newCardsOrder = [...prevCards];

      newCardsOrder[hoverIndex] = prevCards[dragIndex];
      newCardsOrder[dragIndex] = prevCards[hoverIndex];

      return newCardsOrder;
    });
  }

  const dndBackend = "ontouchstart" in window ? TouchBackend : HTML5Backend;

  function shouldButtonBeDisabled() {
    if (prevCL.current.length === 0) {
      return false;
    }

    let disabled = true;

    for (let i = 0; i < careerLaunchCards.length; i++) {
      if (careerLaunchCards[i].id !== prevCL.current[i].id) {
        disabled = false;
      }
    }

    return disabled;
  }

  const disabledStatus = shouldButtonBeDisabled();

  function sendPreferences() {
    const userPreferences = [];

    let currentPreference = 1;
    for (const preference of careerLaunchCards) {
      const payload = {
        eventId: preference.id,
        preference: currentPreference,
      };

      userPreferences.push(payload);
      currentPreference++;
    }
    Axios.post(`${APIURL}/preference/${user.id}`, userPreferences)
      .then(() => {
        toastObj.current.type = "success";
        toastObj.current.message = "Preferințele au fost salvate";

        prevCL.current = careerLaunchCards;
        setCareerLaunchCards([...prevCL.current]);
      })
      .catch((error) => {
        console.log(error);

        toastObj.current.type = "error";
        toastObj.current.message = "Eroare la salvarea preferințelor";
      })
      .finally(() => {
        setOpenToast(true);
      });
  }

  return (
    <div>
      <h1 className={styles.title}>Career Launch</h1>
      <div className={styles.eventsDescriere}>
        {careerLaunchDescription}
        <div />
      </div>
      <h1 className={styles.CLEmail}>
        Email-ul tău pentru Career Launch:&nbsp;
        <span className={styles.userEmail}>{user.email}</span>
        <img
          onClick={openModal}
          src="/icons/info-icon.svg"
          alt="info"
          className={styles.infoIcon}
        />
      </h1>

      {new Date() > technicalTestsStart && new Date() < technicalTestsEnd && (
        user.cvIsUploaded !== null ? (
        <h2 className={styles.miniHeader}>
          Trage și mută pentru a schimba ordinea preferințelor
        </h2>
        ) : (
          <p className={styles.warningCv}>
          Adaugă CV-ul pentru a putea participa la Career Launch
        </p>
        )
      )}

      <CustomModal
        onClose={closeModal}
        isOpen={isModalOpen}
        title="Participare Career Launch"
      >
        <div>
          <p className={styles.modalParagraph}>
            Pentru a participa la Career Launch, trebuie să susții cel puțin un
            test tehnic
          </p>
          <p className={styles.modalParagraph}>
            De asemenea, trebuie să ordonezi companiile în funcție de
            preferințele tale. Dacă treci de prima etapă la mai mult de o
            companie, vei fi selectat pentru etapă 2 a companiei de pe poziția 1
            în lista ta de preferințe
          </p>
          <p>
            Atenție: Pentru ca rezultatul testului tehnic să fie luat în calcul,
            trebuie să te conectezi pe platforma de testare a companiei cu
            email-ul <span className={styles.emailAccent}>{user.email}</span>
          </p>
        </div>
      </CustomModal>

      {/* pt drag & drop */}
      <DndProvider backend={dndBackend}>
        <div className={styles.cardsContainer}>
          {user.cvIsUploaded !== null && (<EventStatus
            eventTitle="Career Launch"
            startDate={technicalTestsStart}
            endDate={technicalTestsEnd}
            messageStart="va începe în curând"
            messageEnd="- etapa testelor tehnice s-a terminat"
          >
            {careerLaunchCards.map((clCompanyData, index) => {
              return (
                // nu schimbati key-ul
                <div key={index} className={styles.containerCLCard}>
                  <h2 className={styles.miniHeader}>{index + 1}</h2>
                  <CLCard
                    clCompanyData={clCompanyData}
                    moveCLCard={moveCLCard}
                    index={index}
                    isAdobe={clCompanyData.id === clAdobe.id}
                  />
                </div>
              );
            })}
          </EventStatus>)}
        </div>
      </DndProvider>

      <div className={styles.buttonContainer}>
        {user.cvIsUploaded && Date.now() > technicalTestsStart && Date.now() < technicalTestsEnd && (
          <Button
            onClick={sendPreferences}
            variant="contained"
            disabled={disabledStatus}
            sx={{
              backgroundColor: "#074A29",
              borderRadius: "1.5rem",
              "&:hover": {
                backgroundColor: "#0E7E42",
              },
              "&.Mui-disabled": {
                backgroundColor: "lightgray",
              },
            }}
          >
            Salvează preferințele
          </Button>
        )}
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        TransitionComponent={Slide}
      >
        <Alert severity={toastObj.current.type}>
          {toastObj.current.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default CareerLaunchPage;
