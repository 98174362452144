import React, { useEffect, useState } from 'react'

import styles from "./CustomModal.module.css"

import { Modal } from '@mui/material'

function CustomModal({ children, title, isOpen, onClose }) {


    
  return (
    <Modal
    open={isOpen}
    onClose={onClose}
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
    >
      <div className={styles.modalContainer}>
        <h4 className={styles.modalHeader}>{title}</h4>
        {children}
      </div>
  </Modal>
  )
}

export default CustomModal