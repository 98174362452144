import React from "react";

import styles from "./LoginContainer.module.css";

function LoginContainer({ children, linkUrl }) {
  const message = linkUrl === "/" ? "Sign up" : "Login";

  return (
    <div>
      <div className={styles.loginContainerBorder}>
        <div className={styles.loginContainer}>
          <img src="/icons/siglaITFest.svg" alt="logo ITFest" height={50} />
          <div className={styles.loginHeader}>
            <h1 className={styles.headerMain}>{message}</h1>
            
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default LoginContainer;
