export const jobHubCompanies = [
  // compania unu
  {
    companyLogo: "/icons/adobe-icon.png",
    details: {
      title: "Adobe", // nume companie
      // descriere companie
      description: (
        <div className="c18 doc-content">
          <p className="c14">
            <span className="c0">Viitorul e al tău! Creează-l! </span>
          </p>
          <p className="c12">
            <span className="c5">
              Adobe Romania este cel mai mare centru de cercetare și dezvoltare
              al produselor Adobe din Europa.{" "}
            </span>
          </p>
          <p className="c19">
            <span className="c5">
              Cu peste 1000 de angajați în prezent, compania lucrează la cele
              mai cunoscute produse Adobe din lume, &nbsp;precum Adobe Express,
              Adobe Experience Manager, Adobe Target, Adobe Analytics.{" "}
            </span>
          </p>
          <p className="c20">
            <span className="c5">
              La Adobe, vei contribui la crearea de produse software pregătite
              să schimbe lumea și felul în care &nbsp;lucrăm. De la semnături
              electronice, până la filme de succes, și site-uri de comerț
              electronic, Adobe &nbsp;creează produse ce acoperă o gamă largă de
              nevoi ale clienților noștri.{" "}
            </span>
          </p>
          <p className="c9">
            <span className="c5">
              Când ni te alaturi fie ca student, fie ca absolvent universitar,
              vei lucra cot la cot cu echipele noastre de &nbsp;ingineri, la
              produse live. De asemenea, vei deveni parte dintr-o comunitate
              care prețuiește perspectiva &nbsp;ta și iți așteaptă sfaturile și
              ideile inovative.{" "}
            </span>
          </p>
          <p className="c17">
            <span className="c5">
              Diversitatea și incluziunea sunt unele dintre obiectivele Adobe.
              Compania investește în programe și &nbsp;evenimente interne ce au
              ca scop crearea unui mediu de lucru cât mai confortabil și deschis
              pentru toți &nbsp;angajații. Adobe Romania oferă oportunități
              egale tuturor angajaților și îi susține în dezvoltarea
              &nbsp;profesională. Pentru noi asta înseamnă #adobelife, un loc în
              care toți colegii pot fi ei înșiși și se pot &nbsp;exprima liber.{" "}
            </span>
          </p>
          <p className="c22">
            <span className="c5">
              Dacă vrei să afli mai multe despre programele noastre de
              internship și oportunități în carieră, folosește &nbsp;link-ul de
              mai jos:{" "}
            </span>
          </p>
          <p className="c23">
            <a
              href="https://www.adobe.com/careers/university.html"
              target="_blank"
              className="c6"
            >
              https://www.adobe.com/careers/university.html
            </a>
            <span className="c10">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c0">The future is yours! Create it! </span>
          </p>
          <p className="c7">
            <span className="c3">
              Adobe Romania is the largest product development center in Europe.{" "}
            </span>
          </p>
          <p className="c1">
            <span className="c3">
              With over 1000 employees, the company works on some of the most
              well-known Adobe &nbsp;products worldwide, such as: Adobe Express,
              Adobe Experience Manager, Adobe Target, and &nbsp;Adobe Analytics.{" "}
            </span>
          </p>
          <p className="c16">
            <span className="c3">
              At Adobe, you’ll contribute to the creation of software products
              made to change the world and &nbsp;the way we work. &nbsp;
            </span>
          </p>
          <p className="c15">
            <span className="c3">
              From e-signs to successful movies and e-trade sites, Adobe creates
              products that cover a large &nbsp;variety of our customers’ needs.{" "}
            </span>
          </p>
          <p className="c8">
            <span className="c3">
              Whether you are a student or graduate, by joining us you’ll get to
              work together with our &nbsp;engineering teams on live products.
              Moreover, you’ll become a part of a community that cares
              &nbsp;about your perspectives and wants to hear your innovative
              ideas and advice.{" "}
            </span>
          </p>
          <p className="c4">
            <span className="c3">
              Diversity &amp; Inclusion are some of Adobe’s objectives. We
              invest in internal programs and events &nbsp;that create a
              comfortable and open environment for all our employees.
            </span>
          </p>
          <p className="c11">
            <span className="c3">
              Adobe Romania offers equal opportunities to all employees and
              supports their professional &nbsp;development. For us, this is
              what #adobelife means: a place where our colleagues can be
              &nbsp;themselves and express freely!{" "}
            </span>
          </p>
          <p className="c21">
            <span className="c3">
              If you want to find out more about our internship programs and
              career opportunities, check &nbsp;out the link below:{" "}
            </span>
          </p>
          <p className="c13">
            <a
              href="https://www.adobe.com/careers/university.html"
              target="_blank"
              className="c6"
            >
              https://www.adobe.com/careers/university.html
            </a>
          </p>
        </div>
      ),
    },
  },

  // compania doi
  {
    companyLogo: "/icons/veridion-icon.png",
    details: {
      title: "Veridion", // nume companie
      // descriere companie
      description: (
        <div className="c2 doc-content">
          <p className="c0">
            <span className="c1">
            Veridion is building a refined search engine for business data across all industries,
             revolutionizing the way businesses access and enrich B2B data. An approach that's similar
              to Google's by providing business data enrichment with unprecedented coverage of SMBs,
               accurate classNameification and in-depth insights based on real-time updates.

            </span>
          </p>
          <p className="c0">
            <span className="c1">
            We crawl the entire internet, analyze hundreds of billions of webpages every month, then 
            apply several AI models to break them down into usable business data. This results in tens 
            of crucial data points associated with each company, including descriptions, alternative names, 
            activity classNameifications, and locations, to empower B2B decision engines.
            </span>
          </p>
          <p className="c0">
            <span className="c1">
            We're currently a team of 40+ driven individuals investing our time to solve problems that haven’t
             been solved before anywhere in the world. Even as a young company, at the beginning of our exponential
              growth, we already work with some of the largest organizations in the world. We bring together exceptional
               minds and characters, people with a growth mindset, strongly committed to achieving high performance, both individually and as a team.

            </span>
          </p>
        </div>
      ),
    },
  },

  // compania trei
  {
    companyLogo: "/icons/logo-medicover-tech.png",
    details: {
      title: "Medicover-Tech", // nume companie
      // descriere companie
      description: (
        <div className="c4 doc-content">
          <p className="c1">
            <span className="c0">
            Medicover Tech is part of Medicover Group of Companies - a leading 
            international Healthcare and Diagnostic Services company founded in
             1995 (www.medicover.com). Medicover Group is operating many
              ambulatory clinics, hospitals, special care facilities and
               laboratories on large markets such as Germany, Poland, 
               Romania, Ukraine and India.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
            Medicover Tech delivers software services across the Medicover Group, 
            transforming business requirements into advanced software products and 
            features. This strategic approach significantly enhances the delivery of 
            medical care to patients, ensuring Medicover remains at the forefront of 
            healthcare innovation and service excellence.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
            The software solutions provided by Medicover Tech cater to a wide
             array of needs, serving patients, medical staff, corporate
              clients and supporting departments.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
            For our Healthcare Services division, our products support operationsț
             by making it easier for doctors to keep records and for clinics or 
             hospitals to manage their work. Companies can enroll employees in 
             health plans easily, while patients can manage appointments online.
              Our system helps clinics offer services and handle patients’ needs efficiently.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
            In our Diagnostics Services division, utilizing our products, the patients and 
            doctors can access laboratory test results and order medical services online. 
            Additionally, our corporate solution streamlines order placement and result 
            delivery for partners, while our laboratory software ensures efficient lab
             operations and equipment integration.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
            If your aim is to make an impact and contribute to something meaningful
             through your work, we would be delighted to have the opportunity to get
              to know you better!
            </span>
          </p>
          <p className="c3">
            <span className="c2"></span>
          </p>
        </div>
      ),
    },
  },

  // compania patru
  {
    companyLogo: "/icons/stripe.png",
    details: {
      title: "Stripe", // nume companie
      // descriere companie
      description: (
        <div className="c2 doc-content">
          <p className="c1">
            <span className="c0">
              Stripe is a financial infrastructure platform for businesses. Millions of
              companies—from the world’s largest enterprises to the most ambitious
              startups—use Stripe to accept payments, grow their revenue, and accelerate new
              business opportunities. Our mission is to increase the GDP of the internet, and we
              have a staggering amount of work ahead. That means you have an
              unprecedented opportunity to put the global economy within everyone’s reach
              while doing the most important work of your.ogy
                providers.{" "}
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Stripe offers an interesting middle ground between joining a small startup and
              going to work for a multinational company. We have systems and processes
              optimized for early-career professionals. You will be in an environment that prizes
              rigor, discipline, and reliability—from our products, from our ways of working, and
              from our colleagues. You will learn how the best teams put together extremely
              reliable systems, and you will be responsible for operating these systems, with
              adequate backup when things get tough.
            </span>
          </p>
          <div>
            <span className="c3 c4">
              <div className="c1">
                Roluri:
              </div>
              <div className="c1">
                <a
                  className="c0"
                  href="https://stripe.com/jobs/listing/software-engineer-new-grad/6172800"
                  target="_blank" rel="noreferrer"
                >
                  https://stripe.com/jobs/listing/software-engineer-new-grad/6172800
                </a>
              </div>
              <div className="c1">
              <a
                className="c0"
                href="https://stripe.com/jobs/listing/software-engineer-intern/6172207"
                target="_blank" rel="noreferrer"
              >
                https://stripe.com/jobs/listing/software-engineer-intern/6172207
              </a>
              </div>
            </span>
          </div>
        </div>
      ),
    },
  },
  {
    companyLogo: "/icons/dell.png",
    details: {
      title: "DELL Technologies", // nume companie
      // descriere companie
      description: (
        <div className="c2 doc-content">
          <p className="c1">
            <span className="c0">
            Dell Technologies Romania
      With around 800 employees, Dell Technologies is running its operations in IT, Finance & Accounting, and Sales for the regions of Europe, Middle East and Africa, United States and Australia, and New Zealand, from Romania. We have recently consolidated all our operations within one new top location in Pipera area and we continue to expand our business by providing multiple career opportunities for both junior and senior professionals interested in joining us.

      PROGRESS TAKES ALL OF US
      At Dell Technologies, we know that our team is at the heart of everything we do. Fueled by collaboration, innovation, and drive, our team is made up of people dedicated to driving human progress around the globe. Learn more about some of the strong, smart people that are dedicated to doing their best work and driving success for our customers.

      To view all jobs and apply <a href="https://jobs.dell.com/en/search-jobs" target="_blank">click here.</a> 
            </span>
          </p>
        </div>
      ),
    },
  },
  {
    companyLogo: "/icons/devmind.png",
    details: {
      title: "Devmind", // nume companie
      // descriere companie
      description: (
        <div className="c2 doc-content">
          <p className="c1">
            <span className="c0">
            La Devmind, aducem viitorul tău în industria IT mai aproape,
       prin programe de studiu complete de Backend Developer și Full Stack Developer,
        susținute de traineri cu experiență academică și profesională. Fie că ești student,
         junior sau urmărești o schimbare profesională completă, la Devmind vei găsi un ecosistem
          complet pentru a face pasul următor spre cariera dorită. Cu parteneriate dedicate atât
           cu multinaționale de top, cât și cu universități de renume, misiunea noastră în educație
            este să ajutăm fiecare tânăr pasionat de tehnologie și software, indiferent de nivelul
             său actual sau background, să atingă următorul nivel în programare! 
            </span>
          </p>
        </div>
      ),
    },
  },
];
