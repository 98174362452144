export const workshopRules = (
  <div className="c3 c20 doc-content">
    <ol>
      <li dir="ltr">
          <h1 dir="ltr">
              Organizator
          </h1>
      </li>
    </ol>
    <br/>
    <p dir="ltr">
        Sindicatul Studenților din Facultatea de Cibernetică, Statistică și
        Informatică Economică (SiSC) este cea mai veche organizație studențească din
        Academia de Studii Economice, fiind, totodată, și membru activ ANOSR.
    </p>
    <p dir="ltr">
        În cei 28 de ani de implicare, profesionalism și inovație,  peste 220 de
        proiecte au fost desfășurate cu succes.
    </p>
    <p dir="ltr">
        Misiunea SiSC cuprinde reprezentarea studenților din Facultatea de
        Cibernetică, Statistică și Informatică Economică, susținerea drepturilor
        studenților în mediul academic și dezvoltarea acestora atât pe plan
        personal, cât și profesional, în cadrul proiectelor SiSC.
    </p>
    <br/>
    <p dir="ltr">
        Organizatorul proiectului ITFest și, implicit, al Workshopului este
        Sindicatul Studenților din Facultatea de Cibernetică, Statistică și
        Informatică Economică (SiSC), denumit în continuare „Organizator”, în
        parteneriat cu Facultatea de Cibernetică, Statistică și Informatică
        Economică.
    </p>
    <p dir="ltr">
        Evenimentul se va derula cu respectarea prevederilor cuprinse în prezentul
        regulament (denumit în cele ce urmează „Regulamentul”). Termenii și
        condițiile prezentului Regulament, așa cum sunt prezentate mai jos, sunt
        obligatorii pentru toți participanții evenimentului.
    </p>
    <p dir="ltr">
        Organizatorul își rezervă dreptul de a modifica Regulamentul, aducând la
        cunoștința publicului acest fapt în timp util.
    </p>
    <br/>
    <ol start="2">
        <li dir="ltr">
            <h1 dir="ltr">
                Descrierea proiectului ITFest
            </h1>
        </li>
    </ol>
    <br/>
    <p dir="ltr">
        ITFest este un proiect cu tradiție, aflat la cea de-a XVII-a ediție. Prin
        intermediul diverselor evenimente, ITFest își propune să contribuie la
        diminuarea unor slăbiciuni ale sistemului educațional, precum: lipsa
        contextelor în care tinerii se pot afirma în fața angajatorilor,
        imposibilitatea tinerilor de a dobândi experiența necesară angajării,
        neadaptarea sistemului educațional la nevoile pieței muncii sau existența
        unui caracter preponderent teoretic al sistemului educațional.
    </p>
    <p dir="ltr">
        Evenimentul se dezvoltă în jurul sferei IT și cuprinde Job Hub, Career
        Launch, Workshop-ul tematic, Hackathon și un LAN Party. Ediția din acest an
        se va desfășura în perioada 10 octombrie - 16 noiembrie.
    </p>
    <br/>
    <ol start="3">
        <li dir="ltr">
            <h1 dir="ltr">
                Aspecte organizatorice
            </h1>
        </li>
    </ol>
    <br/>
    <p dir="ltr">
        Workshop-ul va avea loc în perioada 29 octombrie 2024.
    </p>
    <p dir="ltr">
        Acestea vor fi organizate în format fizic și se vor desfășura în
        conformitate cu prevederile prezentului Regulament, fiind accesibile
        oricărei persoane fizice înscrise într-un program de studii preuniversitare
        (liceu) sau universitare, care acceptă termenii și condițiile prezentului
        Regulament.
    </p>
    <br/>
    <ol start="4">
        <li dir="ltr">
            <h1 dir="ltr">
                Dreptul de participare
            </h1>
        </li>
    </ol>
    <br/>
    <p dir="ltr">
        Poate participa orice persoană fizică înscrisă într-un program de studii
        preuniversitare (liceu) sau universitare.
    </p>
    <p dir="ltr">
        Pentru a putea participa la Workshop-uri, persoanele trebuie să se înscrie
        completând formularul de înregistrare aflat pe site-ul
        <a href="https://itfest.sisc.ro/">
            https://itfest.sisc.ro/
        </a>
        și să își încarce CV-ul în cadrul formularului.
    </p>
    <br/>
    <ol start="5">
        <li dir="ltr">
            <h1 dir="ltr">
                Înscrierea și selectarea participanților
            </h1>
        </li>
    </ol>
    <br/>
    <p dir="ltr">
        Înscrierea persoanelor se va face în perioada …………. 2024. Doritorii trebuie
        să se înscrie completând formularul de înregistrare aflat pe site-ul
        <a href="https://itfest.sisc.ro/">
            https://itfest.sisc.ro/
        </a>
        și să își încarce CV-ul în cadrul formularului. După această dată nu se vor
        mai accepta alte aplicații. Înscrișii au obligația să furnizeze informații
        reale și complete în formularul de înscriere la eveniment.
    </p>
    <p dir="ltr">
        Cei care s-au înscris declară expres că au citit, au înțeles și sunt de
        acord cu termenii și condițiile prezentului Regulament. Nu se percep taxe de
        înscriere.
    </p>
    <br/>
    <br/>
    <ol start="6">
        <li dir="ltr">
            <h1 dir="ltr">
                Obligațiile Participanților
            </h1>
        </li>
    </ol>
    <br/>
    <p dir="ltr">
        Prezentele obligații trebuie respectate pe durata perioadei Workshopului,
        precum și după finalizarea sa. Participanții își asumă:
    </p>
    <ul>
        <li dir="ltr">
            <p dir="ltr">
                să nu furnizeze public informații care pot aduce daune imaginii
                Organizatorului;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                să păstreze un comportament decent și să respecte indicațiile
                Organizatorului;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                să își asigure singuri modalitățile de acces la evenimente (laptop,
                conexiune la internet etc.);
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                să nu înregistreze și să difuzeze conținutul Workshopului;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                ca materialele obținute de către Organizator în urma evenimentelor
                (fotografiile și înregistrările video și audio) să fie făcute
                publice pe site-ul
                <a href="http://itfest.sisc.ro">
                    http://itfest.sisc.ro
                </a>
                , pe Instagram la
                <a href="https://www.instagram.com/sisc.ase/?hl=ro">
                    @sisc.ase
                </a>
                , pe paginile de Facebook
                <a href="https://www.facebook.com/it.fest.sisc">
                    ITFest
                </a>
                și <a href="https://www.facebook.com/sisc.ase">SiSC ASE</a>.
            </p>
        </li>
    </ul>
    <p dir="ltr">
        Participanții precum și orice persoană care va fi prezentă pe perioada
        desfășurării evenimentelor înțeleg, sub sancțiunea suportării tuturor
        consecințelor generate de încălcarea acestor obligații.
    </p>
    <br/>
    <ol start="7">
        <li dir="ltr">
            <h1 dir="ltr">
                Confidențialitatea datelor
            </h1>
        </li>
    </ol>
    <br/>
    <p dir="ltr">
        Organizatorul are următoarele obligații în ceea ce privește
        confidențialitatea datelor:
    </p>
    <ul>
        <li dir="ltr">
            <p dir="ltr">
                respectarea și protejarea confidențialității datelor personale din
                formularele de înscriere;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                prelucrarea și folosirea informațiilor personale conform politicii
                de confidențialitate a datelor personale;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                informațiile personale nu vor fi folosite în niciun mod care
                contravine politicii menționate anterior;
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                materialele trimise de Participanți nu vor fi folosite în alte
                scopuri ce nu țin de eveniment.
            </p>
        </li>
    </ul>
    <p dir="ltr">
        Organizatorul își rezervă dreptul de a oferi partenerilor CV-urile
        Participanților.
    </p>
    <br/>
    <ol start="8">
        <li dir="ltr">
            <h1 dir="ltr">
                Dreptul de autor
            </h1>
        </li>
    </ol>
    <br/>
    <p dir="ltr">
        Prezentările lucrărilor sau a oricăror alte materiale realizate în vederea
        concursului nu vor rămâne în proprietatea Organizatorului.
    </p>
    <p dir="ltr">
        Organizatorul nu își asumă nicio răspundere în cazul în care lucrările
        realizate în urma concursului folosesc elemente de conținut (texte, imagini,
        coduri sursă etc.) care încalcă drepturile de autor. Întreaga răspundere
        aparține celui care a realizat lucrarea.
    </p>
    <br/>
    <ol start="9">
        <li dir="ltr">
            <h1 dir="ltr">
                Litigii
            </h1>
        </li>
    </ol>
    <br/>
    <p dir="ltr">
        Eventualele litigii apărute între Organizator și Participant se vor rezolva
        pe cale amiabilă.
    </p>
    <p dir="ltr">
        În cazul în care un Participant are de semnalat un incident în legătură cu
        desfășurarea evenimentului, se va adresa Organizatorului cu o sesizare
        scrisă în acest sens trimisă pe adresa itfest@sisc.ro, în termen de maximum
        15 zile de la data incidentului, Organizatorul fiind obligat să ofere un
        răspuns în maximum 30 de zile.
    </p>
    <p dir="ltr">
        Organizarea proiectului poate fi întreruptă doar în cazul apariţiei unor
        evenimente importante neprevăzute sau din motive independente de voinţa
        Organizatorilor.
    </p>
    <br/>
    <ol start="10">
        <li dir="ltr">
            <h1 dir="ltr">
                Dispoziții finale
            </h1>
        </li>
    </ol>
    <br/>
    <p dir="ltr">
        Prezentul Regulament este valabil pentru ediția din anul 2024 a proiectului
        ITFest și are caracter obligatoriu, atât pentru Organizator, cât și pentru
        Participanți.
    </p>
    <p>
        Alte acţiuni întreprinse de Organizator şi nereglementate de către
        regulament, vor fi analizate de ambele părţi spre un acord comun, atât al
        Participanţilor, cât şi al Organizatorilor, astfel încât aceste acţiuni să
        nu pună în pericol desfăşurarea normală a proiectului.
    </p>
  </div>
);
