import React, { useState } from "react";
import styles from "./LogInPage.module.css";
import InputError from "../../../components/inputError/InputError";
import { Button, TextField, Snackbar, Alert, Slide } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import Axios from "axios";
import useStore from "../../../store/userStore";
import { useNavigate, Link } from "react-router-dom";
import { APIURL } from "../../../constants";
import LoginContainer from "../../../components/form/LoginContainer";

function LogInPage() {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: JSON.parse(localStorage.getItem("user"))?.email || "",
      password: JSON.parse(localStorage.getItem("user"))?.password || "",
    },
  });

  const [setUser, setEventsAttended] = useStore((state) => [
    state.setUser,
    state.setEventsAttended,
  ]);

  

  const navigate = useNavigate();

  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [visisblePassword, setVisisiblePassword] = useState(false);

  const currentEyeIcon = visisblePassword
    ? "/icons/eye-slash-icon.svg"
    : "/icons/eye-icon.svg";
  const currentEyeAlt = visisblePassword ? "hide-password" : "show-password";

  function onSubmit(formData) {
    Axios.post(`${APIURL}/user/login`, formData, {
      withCredentials: true,
    })
      .then((response) => {
        return response.data;
      })
      .then((userDB) => {
        delete userDB.password;
        console.log(userDB);
        setUser(userDB);

        Axios.get(`${APIURL}/attendance/${userDB.id}`).then((attendancesDB) => {
          setEventsAttended(attendancesDB.data);

          navigate("/profile");
        });
      })
      .catch((error) => {
        setInvalidCredentials(true);
        console.log(error);
      });
  }

  return (
    <div className={styles.loginContainer}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={invalidCredentials}
        autoHideDuration={3000}
        onClose={() => setInvalidCredentials(false)}
        TransitionComponent={Slide}
      >
        <Alert severity="error">Credențiale invalide</Alert>
      </Snackbar>

      <LoginContainer linkUrl="/sign-up">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.fieldContainer}>
            <img src="/icons/user-icon.svg" alt="user" height="30px" />
            <Controller
              control={control}
              name="email"
              rules={{
                required: {
                  value: true,
                  message: "Emailul trebuie completat",
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Email invalid",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  variant="standard"
                  error={errors.email ? true : false}
                  InputLabelProps={{
                    style: {
                      color: "#399345",
                    },
                  }}
                  sx={{
                    input: {
                      color: "#399345",
                      minWidth: "200px",
                      width: "30vw",
                      maxWidth: "280px",
                      paddingRight: "35px",
                    },
                    "& .MuiInput-underline:before, & .MuiInput-underline:after":
                      {
                        borderBottomColor: "#399345",
                      },
                  }}
                />
              )}
            />
          </div>
          <InputError errorObj={errors} field="email" />

          <div className={styles.fieldContainer}>
            <img src="/icons/lock-icon.svg" alt="user" height="30px" />
            <Controller
              control={control}
              name="password"
              rules={{
                required: {
                  value: true,
                  message: "Parola trebuie completată",
                },
                minLength: {
                  value: 8,
                  message: "Parola trebuie să aibă minim 8 caractere",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type={visisblePassword ? "text" : "password"}
                  label="Password"
                  variant="standard"
                  error={errors.password ? true : false}
                  InputLabelProps={{
                    style: {
                      color: "#399345",
                    },
                  }}
                  sx={{
                    input: {
                      color: "#399345",
                      minWidth: "200px",
                      width: "30vw",
                      maxWidth: "280px",
                      paddingRight: "35px",
                    },
                    "& .MuiInput-underline:before, & .MuiInput-underline:after":
                      {
                        borderBottomColor: "#399345",
                      },
                  }}
                />
              )}
            />
            <img
              onClick={() => setVisisiblePassword((prevState) => !prevState)}
              src={currentEyeIcon}
              alt={currentEyeAlt}
              height="30px"
              className={styles.eyeIcon}
            />
          </div>
          <InputError errorObj={errors} field="password" />

          <div className={styles.buttonContainer}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#399345",
                color: "white",
                borderRadius: "1rem",
                marginTop: "3vh",
                padding: "10px 40px",
                "&:hover": {
                  backgroundColor: "var(--lime-green)",
                },
              }}
            >
              Login
            </Button>

            <div className={styles.loginSwitchPage}>
              Nu ai cont?{" "}
              <Link to={"/sign-up"} style={{ textDecoration: "underline" }}>
                Înregistrează-te
              </Link>
            </div>
            <Link
              to={"/forgot-password"}
              className={styles.forgotpassword}
            >
              <u>Ai uitat parola?</u>
            </Link>
          </div>
        </form>
      </LoginContainer>
    </div>
  );
}

export default LogInPage;
