import React from "react";
import styles from "./LanCountdown.module.css";
import Countdown from "react-countdown";

const lanStarts = new Date("2024-11-16T00:00:00");

function LanCountdown() {
  const stopwatch = ({ days, hours, minutes }) => {
    return (
      <div className={styles.countdownContainer}>
        <p className={styles.timerDays}>{days} zile</p>
        <p className={styles.timerHours}>{hours} ore</p>
        <p className={styles.timerMinutes}>{minutes} minute</p>
      </div>
    );
  };

  return (
    <div className={styles.lan} >
      <h2 className={styles.headerLan}>Lan Party</h2>
      <p className={styles.announcementLan}>
        {"Începe în:"}
      </p>
      <Countdown date={lanStarts} renderer={stopwatch} />
    </div>
  );
}

export default LanCountdown;
