import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Sidebar.module.css";
import LinkTo from "./LinkTo";

import { Drawer, useTheme, useMediaQuery, Box } from "@mui/material";

import useStore from "../../store/userStore";

const pages = [
  {
    pageTitle: "Dashboard",
    link: "/dashboard",
  },
  {
    pageTitle: "qr-code",
    link: "/qr-code",
  },
];

function AdminSidebar({ children }) {
  const [setUser] = useStore((state) => [state.setUser]);

  const theme = useTheme();
  const isTabletOrLarger = useMediaQuery(theme.breakpoints.up("md"));

  const [openMobile, setOpenMobile] = useState(false);

  function sidebarOpen() {
    if (openMobile) {
      return true;
    }

    return false;
  }

  function onLogout() {
    setUser(null);

    const ITFestEnd = new Date("2023-10-29")
    if(new Date() > ITFestEnd){
      localStorage.clear("user")
    }
  }

  const navigate = useNavigate();

  return (
    <div>
      <Box
        className={styles.burgerMenu}
        sx={{
          display: "block" ,
          position: { xs: "relative", md: "absolute" },
          zIndex: 3
        }}
      >
        <img
          onClick={() => setOpenMobile(true)}
          src="icons/burger-menu.svg"
          alt="burger-menu"
          height="50px"
        />
      </Box>

      <Drawer
        anchor="left"
        open={sidebarOpen()}
        onClose={() => setOpenMobile(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiPaper-root": {
            boxSizing: "border-box",
            background: "black",
            width: "18vw",
            minWidth: "250px",
            maxHeight: "100vh",
            display: "flex",
            alignItems: "center",
            borderRight: "2px solid #399345",
            position: "absolute",
            zIndex: 2
          },
        }}
      >
        <div className={styles.eventsSidebar}>
          <img
            className={styles.logoImage}
            src="/icons/siglaITFest.svg"
            alt="ITFest Logo"
            height="50px"
            onClick={() => navigate("/profile")}
          />
          <div className={styles.eventsContainer}>
            {pages.map((page, index) => (
              <LinkTo key={index} pageTitle={page.pageTitle} link={page.link} />
            ))}
          </div>
          <div className={styles.buttonContainer}>
            <button onClick={onLogout} className={styles.eventsButton}>
              Log Out
            </button>
          </div>
        </div>
      </Drawer>

      <div
        style={{
          marginLeft: 0,
          transition: "margin-left 0.3s",
        }}
        className={styles.pagesContainer}
      >
        {children}
      </div>
    </div>
  );
}

export default AdminSidebar;
