import React, { useCallback, useRef, useState } from "react";
import styles from "./SignUpPage.module.css";

import FormContainer from "../../../components/form/FormContainer";

import { useForm, FormProvider, Controller } from "react-hook-form";
import {
  Alert,
  Button,
  MenuItem,
  Select,
  Slide,
  Snackbar,
  InputLabel,
  FormControl,
  Checkbox,
  TextField,
  styled,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";

import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { registerRules } from "./registerRules";

import "filepond/dist/filepond.min.css";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import Axios from "axios";
import { GDPRText } from "./GDPRText";
import CustomModal from "../../../components/modal/CustomModal";
import { useNavigate, Link } from "react-router-dom";
import { APIURL } from "../../../constants";

registerPlugin(FilePondPluginFileValidateType);

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ffad4a",
  },
  "& .MuiInputBase-input": {
    color: "#1a9a32",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#399345",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#399345",
    },
    "&:hover fieldset": {
      borderColor: "#074a29",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#399345",
    },
  },
});

function SignUpPage() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      lastName: "",
      firstName: "",
      email: "",
      password: "",
      phoneNumber: "",
      university: "",
      faculty: "",
      year: "",
      isAdmin: false,
      CV: null,
      GDPR: false,
    },
  });

  const firstName = getValues("firstName");
  const lastName = getValues("lastName");
  const navigate = useNavigate();
  const [invalidFields, setInvalidFields] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  let errorMessage = useRef("");
  function onError() {
    errorMessage.current = "Există câmpuri cu erori";
    setInvalidFields(true);
  }

  function onSubmit(formData) {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    Axios.post(`${APIURL}/user`, formData, {
      withCredentials: true,
    })
      .then((userDB) => {
        console.log(formData);
        // setUser(userDB.data);
        const userID = userDB.data.id;
        // navigate("/profile");
        // localStorage.setItem("user", JSON.stringify(formData));
        return userID;
      })
      .then((userID) => {
        const payload = new FormData();
        console.log(userID);
        payload.append(
          `${userID}_${formData.firstName}_${formData.lastName}`,
          formData.CV
        );
        if (formData.CV !== null) {
          Axios.post(`${APIURL}/cvs`, payload, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }
        setIsLoading(false);
        navigate("/");
      })
      .catch((error) => {
        if (error.response) {
          errorMessage.current = error.response.data;
          setInvalidFields(true);
        } else {
          errorMessage.current = "Eroare necunoascuta";
        }

        setIsLoading(false);
      });
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={invalidFields}
        autoHideDuration={3000}
        onClose={() => setInvalidFields(false)}
        TransitionComponent={Slide}
      >
        <Alert severity="error">{errorMessage.current}</Alert>
      </Snackbar>
      <div className={styles.signUpContainer}>
        <FormContainer linkUrl="/">
          <FormProvider {...register}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <div className={styles.formFieldContainer}>
                <CssTextField
                  label="Nume "
                  multiline
                  {...register("lastName", registerRules.lastName)}
                  error={!!errors.lastName}
                  helperText={
                    errors.lastName ? `${errors.lastName.message}` : ""
                  }
                />
                <CssTextField
                  label="Prenume "
                  multiline
                  {...register("firstName", registerRules.firstName)}
                  error={!!errors.firstName}
                  helperText={
                    errors.firstName ? `${errors.firstName.message}` : ""
                  }
                />
                <CssTextField
                  label="Email"
                  multiline
                  {...register("email", registerRules.email)}
                  error={!!errors.email}
                  helperText={errors.email ? `${errors.email.message}` : ""}
                />

                <FormControl
                  sx={{
                    "& label.Mui-focused": {
                      color: "#ffad4a",
                    },
                    "& .MuiInputBase-input": {
                      color: "#1a9a32",
                      height: "3.5rem",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#399345",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#399345",
                      },
                      "&:hover fieldset": {
                        borderColor: "#074a29",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#399345",
                      },
                    },
                  }}
                  error={!!errors.password}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Parolă
                  </InputLabel>
                  <OutlinedInput
                    {...register("password", registerRules.password)}
                    label="Parolă "
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            color: "#b6e29f",
                          }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          onMouseUp={handleMouseUpPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors.password && (
                    <FormHelperText>{errors.password.message}</FormHelperText>
                  )}
                </FormControl>

                <CssTextField
                  label="Număr de telefon"
                  multiline
                  {...register("phoneNumber", registerRules.phoneNumber)}
                  error={!!errors.phoneNumber}
                  helperText={
                    errors.phoneNumber ? `${errors.phoneNumber.message}` : ""
                  }
                />
                <CssTextField
                  label="Universitate "
                  multiline
                  {...register("university", registerRules.university)}
                  error={!!errors.university}
                  helperText={
                    errors.university ? `${errors.university.message}` : ""
                  }
                />
                <CssTextField
                  label="Facultate "
                  multiline
                  {...register("faculty", registerRules.faculty)}
                  error={!!errors.faculty}
                  helperText={errors.faculty ? `${errors.faculty.message}` : ""}
                />
                <Controller
                  name="year"
                  control={control}
                  rules={registerRules.year}
                  render={({ field }) => {
                    return (
                      <FormControl
                        error={!!errors.year}
                        fullWidth
                        sx={{
                          marginBottom: "15px",
                        }}
                      >
                        <InputLabel
                          id="an-studiu"
                          sx={{
                            "&.Mui-focused": {
                              color: "#ffad4a",
                            },
                          }}
                        >
                          Anul de studiu
                        </InputLabel>
                        <Select
                          variant="outlined"
                          labelId="an-studiu"
                          label="Anul de studiu"
                          value={field.value}
                          onChange={field.onChange}
                          sx={{
                            "& .MuiSelect-icon": {
                              color: "#ffad4a",
                            },
                            color: "#399345",
                            "& fieldset": {
                              borderColor: "#399345",
                            },
                            "& label.Mui-focused": {
                              color: "#ffad4a",
                            },
                          }}
                        >
                          <MenuItem value="c11">Clasa a-11-a</MenuItem>
                          <MenuItem value="c12">Clasa a-12-a</MenuItem>
                          <MenuItem value="l1">Anul I</MenuItem>
                          <MenuItem value="l2">Anul II</MenuItem>
                          <MenuItem value="l3">Anul III</MenuItem>
                          <MenuItem value="l4">Anul IV</MenuItem>
                          <MenuItem value="m1">Master I</MenuItem>
                          <MenuItem value="m2">Master II</MenuItem>
                          <MenuItem value="f">Proaspăt absolvent</MenuItem>
                        </Select>
                      </FormControl>
                    );
                  }}
                />
              </div>

              <p className={styles.inputError}>
                {errors.year && errors.year.message}
              </p>

              <div className={styles.containerCV}>
                <Controller
                  control={control}
                  name="CV"
                  render={({ field }) => (
                    <FilePond
                      name={`${firstName}_${lastName}`}
                      acceptedFileTypes={"application/pdf"}
                      labelIdle={`<u id=${styles.uploadCV}>Încarcă CV</u>`}
                      onupdatefiles={() => {
                        field.onChange(true);
                      }}
                      credits={false}
                      dropOnPage
                      dropValidation
                      className={styles.changeCV}
                      id={`${firstName}_${lastName}`}
                    />
                  )}
                />
              </div>

              <Controller
                name="GDPR"
                control={control}
                rules={{
                  validate: (GDPRChecked) => {
                    if (GDPRChecked === false) {
                      return "Regulamentul GDPR trebuie acceptat pentru a-ți crea cont";
                    }
                  },
                }}
                render={({ field }) => {
                  return (
                    <div className={styles.GDPRContainer}>
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        style={{
                          color: "green",
                        }}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 32,
                          },
                        }}
                      />

                      <p>
                        Sunt de acord cu regulamentul{" "}
                        <span onClick={openModal} className={styles.GDPRWord}>
                          GDPR
                        </span>
                      </p>
                    </div>
                  );
                }}
              />

              <CustomModal
                onClose={closeModal}
                isOpen={isModalOpen}
                title="Regulament GDPR ITFest"
              >
                {GDPRText}
              </CustomModal>

              <p className={styles.inputError}>
                {errors.GDPR && errors.GDPR.message}
              </p>

              <div className={styles.buttonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#399345",
                    color: "white",
                    borderRadius: "1rem",
                    marginTop: "3vh",
                    padding: "10px 40px",
                    "&:hover": {
                      backgroundColor: "#075011",
                    },
                    width: "80%",
                  }}
                  className={isLoading ? styles.inactiveBtn : null}
                >
                  Register
                </Button>

                <div className={styles.loginSwitchPage}>
                  Ai deja cont?{" "}
                  <Link to={"/"} style={{ textDecoration: "underline" }}>
                    Loghează-te
                  </Link>
                </div>
              </div>
            </form>
          </FormProvider>
        </FormContainer>
      </div>
    </div>
  );
}

export default SignUpPage;
