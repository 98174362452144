export const careerLaunchDescription = (
  <div class="c7 doc-content">
    <p>   
    &emsp;Career Launch are ca scop familiarizarea studenților cu procesul de
    recrutare. Toată lumea este conștientă că interviurile de angajare pot fi
    tricky din multe puncte de vedere, mai ales dacă nu ai o sursă de unde să te
    pregătești sau nu ai niciun fel de material pe care să îl citești.
</p>
<p>
    &emsp;Astfel că, prin Career Launch vei putea trece prin doua etape în urma cărora
    te vei simți mai confortabil cu procesul de recrutare din domeniul IT. În
    prima etapa, vei susține testele tehnice online ale companiilor partenere,
    prin care iti vei putea evalua skill-urile de algoritmică. Apoi, urmează ca
    noi și partenerii să alegem participanți care s-au descurcat cel mai bine
    pentru etapa a doua. Această ultimă  etapă a Career Launch va avea loc la
    sediul companiei și va fi constituită dintr-o prezentare a companiei.
</p>
<p>
    &emsp;Pentru a vă înscrie la eveniment, trebuie doar să susțineți testele tehnice
    și să aveți pregătit CV-ul care va impresiona angajatorii!
</p>
<p>
    Perioadă testelor tehnice: 8-13 octombrie
</p>
<div>
    <br/>
</div>
  </div>
);
