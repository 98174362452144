import React, { useEffect, useState } from "react";
import styles from "./AttendanceStatus.module.css";
import Axios from "axios";
import { APIURL } from "../../constants";

function AttendanceStatus({ eventID }) {
  const [signedUpUsers, setSignedUpUsers] = useState();
  const [participants, setParticipants] = useState();

  useEffect(() => {
    Axios.get(`${APIURL}/attendance/signedUps/${eventID}`, {
      withCredentials: true,
    })
      .then((res) => {
        setSignedUpUsers(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

    Axios.get(`${APIURL}/attendance/participants/${eventID}`, {
      withCredentials: true,
    })
      .then((res) => {
        setParticipants(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
      <div className={styles.attendanceContainer}>
        Participanti:{participants}/Inscrisi:{signedUpUsers}
      </div>
  );
}

export default AttendanceStatus;
