import React, { useState, useRef, useCallback } from "react";
import TeamMember from "./TeamMember/TeamMember";
import CustomModal from "../modal/CustomModal";
import styles from "./CaptainTeam.module.css";
import AddMemberForm from "./AddMemberForm/AddMemberForm";
import { useEffect } from "react";
import Axios from "axios";
import { APIURL } from "../../constants";
import useStore from "../../store/userStore";
import { Alert, Button, Slide, Snackbar } from "@mui/material";

const CaptainTeam = ({
  display,
  userId,
  paramEventId,
  paramTeamId,
  teamSize,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const [openToast, setOpenToast] = useState(false);
  const toastObj = useRef({
    type: "info",
    message: "",
  });
  const isTeamCaptain = useStore((state) => state.isCaptain);
  const setTeamCaptain = useStore((state) => state.setCaptain);
  const [team, setTeam] = useState([]);
  const [teamName, setTeamName] = useState("");

  useEffect(() => {
    if (paramTeamId !== 0) {
      Axios.get(`${APIURL}/user/getTeam/${paramTeamId}`, {
        withCredentials: true,
      })
        .then((res) => {
          setTeam(res.data.teamMembers);
          setTeamName(res.data.teamName);
          if (res.data.captainId === userId) {
            setTeamCaptain(true);
          } else {
            setTeamCaptain(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [team.length, userId, setTeamCaptain, paramTeamId]);

  const deleteMember = useCallback(
    (memberId) => {
      Axios.post(`${APIURL}/hackathon/deleteUser`, {
        eventId: paramEventId,
        userId: memberId,
        teamId: paramTeamId,
      })
        .then(() => {
          const updatedTeam = team.filter((member) => member.id !== memberId);
          setTeam(updatedTeam);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [paramEventId, paramTeamId, team]
  );
  // Optional de adaugat isloading
  const addMember = useCallback(
    (memberId) => {
      console.log(
        paramEventId,
        paramTeamId,
        memberId,
        "paramEventId, paramTeamId, memberId"
      );

      Axios.post(`${APIURL}/hackathon/addUser`, {
        eventId: paramEventId,
        userId: memberId,
        teamId: paramTeamId,
      })
        .then((res) => {
          setTeam([...team, res.data]);
          console.log(team);
          console.log("Merge");
        })
        .catch((error) => {
          toastObj.current.type = "error";
          console.log(error.response);
          toastObj.current.message = error.response.data;
          setOpenToast(true);
        });
      setIsModalOpen(false);
    },
    [paramEventId, paramTeamId, team]
  );

  return (
    <div className={display ? styles.display : styles.nedisplay}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        TransitionComponent={Slide}
      >
        <Alert severity={toastObj.current.type}>
          {toastObj.current.message}
        </Alert>
      </Snackbar>

      <h2 className={styles.titlu}>Echipa ta: {teamName}</h2>
      <div className={styles.contur}>
        {team.map((membru) => (
          <TeamMember
            key={membru.id}
            id={membru.id}
            name={membru.name}
            isTeamCaptain={isTeamCaptain ? membru.id !== userId : false}
            onDelete={deleteMember}
          />
        ))}
        {isTeamCaptain && team.length < teamSize && (
          <Button
            onClick={openModal}
            sx={{
              backgroundColor: "#ffad4a",
              color: "black",
              marginTop: "1vh",
              padding: "10px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#f2911b",
              },
            }}
          >
            Adaugă membru
          </Button>
        )}
      </div>

      <CustomModal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Adaugă membru nou"
      >
        <AddMemberForm onSave={addMember} />
      </CustomModal>
    </div>
  );
};

export default CaptainTeam;
