import { hackathonRules } from "../../pages/user-pages/hackathon/hackathonRules";

export const hackathonAdobe = {
  id: 4,
  companyLogo: "/icons/adobe-icon.png",
  details: {
    title: "Hackathon Adobe",
    description: (
      <div class="c0 doc-content">
        <h3>Resource Wise</h3>
        <p class="c1">
          <span class="c2"></span>
        </p>
        <p class="c3">
          <span class="c2">
            Share, Save, Connect Description: In a world striving to reduce
            waste and strengthen communities, Resource Wise emerges as the
            ultimate solution.{" "}
          </span>
        </p>
        <p class="c3">
          <span class="c2">
            Build an application to combat resource waste and connect those with
            surplus resources to those in need. For example, one use-case of
            this application could be connecting restaurants that have food
            prepared at the end of the day, with shelters or people in need.{" "}
          </span>
        </p>
        <p class="c3">
          <span>
            You can include features such as Interactive Mapping, to locate
            nearby donors and beneficiaries, minimizing resource transportation
            and environmental impact, or Gamification, to Earn rewards and
            points for your contributions, making your journey to reduce waste
            an enjoyable and fulfilling one.
          </span>
        </p>
      </div>
    ),
  },
  rules: {
    title: "Regulament Hackathon ITFest 2024",
    text: hackathonRules,
  },
};
