export const hackathonRules = (
  <div class="c3 c26 doc-content" style={{textAlign:"justify"}}>
    <ol>
        <li dir="ltr">
            <h1 dir="ltr">
                Organizator
            </h1>
        </li>
    </ol>
    <p dir="ltr">
        Sindicatul Studenților din Facultatea de Cibernetică, Statistică și
        Informatică Economică (SiSC) este cea mai veche organizație studențească din
        Academia de Studii Economice, fiind, totodată, și membru activ ANOSR.
    </p>
    <p dir="ltr">
        În cei 28 de ani de implicare, profesionalism și inovație,  peste 220 de
        proiecte au fost desfășurate cu succes.
    </p>
    <p dir="ltr">
        Misiunea SiSC cuprinde reprezentarea studenților din Facultatea de
        Cibernetică, Statistică și Informatică Economică, susținerea drepturilor
        studenților în mediul academic și dezvoltarea acestora atât pe plan
        personal, cât și profesional, în cadrul proiectelor SiSC.
    </p>
    <br/>
    <p dir="ltr">
        Organizatorul proiectului ITFest și, implicit, al Maratonului de programare
        (denumit în continuare „Concursul”) este Sindicatul Studenților din
        Cibernetică (SiSC), denumit în continuare Organizator”, în parteneriat cu
        Facultatea de Cibernetică, Statistică și Informatică Economică.
    </p>
    <p dir="ltr">
        Concursul se va derula cu respectarea prevederilor cuprinse în prezentul
        regulament (denumit în cele ce urmează „Regulamentul”). Termenii și
        condițiile prezentului Regulament, așa cum sunt prezentate mai jos, sunt
        obligatorii pentru toți participanții Concursului.
    </p>
    <p dir="ltr">
        Organizatorul își rezervă dreptul de a modifica Regulamentul, aducând la
        cunoștința publicului acest fapt în timp util.
    </p>
    <br/>
    <ol start="2">
        <li dir="ltr">
            <h1 dir="ltr">
                Descrierea proiectului ITFest
            </h1>
        </li>
    </ol>
    <p dir="ltr">
        ITFest este un proiect cu tradiție, aflat la cea de-a XVII-a ediție. Prin
        intermediul diverselor evenimente, ITFest își propune să contribuie la
        diminuarea unor slăbiciuni ale sistemului educațional, precum: lipsa
        contextelor în care tinerii se pot afirma în fața angajatorilor,
        imposibilitatea tinerilor de a dobândi experiența necesară angajării,
        neadaptarea sistemului educațional la nevoile pieței muncii sau existența
        unui caracter preponderent teoretic al sistemului educațional.
    </p>
    <p dir="ltr">
        Evenimentul se dezvoltă în jurul sferei IT și cuprinde Job Hub, Career
        Launch, Workshop tematic, Hackathon și un LAN Party.
    </p>
    <p dir="ltr">
        Ediția din acest an se va desfășura în perioada 10 octombrie - 16 noiembrie.
    </p>
    <br/>
    <ol start="3">
        <li dir="ltr">
            <h1 dir="ltr">
                Durata și aria de desfășurare
            </h1>
        </li>
    </ol>
    <p dir="ltr">
        Concursurile, două pe zi, vor avea loc în data de 19 octombrie și 2
        noiembrie, sub forma unor Maratoane de programare (Hackathon) cu o durată de
        12 ore.
    </p>
    <p dir="ltr">
        Concursurile sunt organizate și se vor desfășura fizic și online, în
        conformitate cu prevederile prezentului Regulament, fiind accesibil oricărei
        persoane fizice, înrolată într-un program de liceu, facultate sau master,
        care acceptă termenii și condițiile prezentului Regulament.
    </p>
    <p dir="ltr">
        Concurenţii nu au voie să converseze cu nimeni în afară de membrii echipei
        lor şi personalul desemnat de către organizatori. Personalul de asistenţă
        poate sfătui participanţii în legătură cu probleme de sistem, precum
        explicarea mesajelor de eroare ale sistemului.
    </p>
    <p dir="ltr">
        Concurenții pot fi descalificați de către organizatori pentru orice
        activitate care periclitează competiţia, cum ar fi modificarea neautorizată
        a materialelor de concurs sau comportamentul de distragere a atenţiei.
    </p>
    <br/>
    <ol start="4">
        <li dir="ltr">
            <h1 dir="ltr">
                Dreptul de participare
            </h1>
        </li>
    </ol>
    <p dir="ltr">
        Poate participa orice persoană fizică, înrolată într-un program de liceu,
        facultate sau master.
    </p>
    <p dir="ltr">
        În cazul în care un participant este minor, este obligatorie prezența la
        eveniment cu acordul parental (anexat la sfârșitul Regulamentului) completat
        de unul dintre părinții sau tutorii legali ai minorului.
    </p>
    <p dir="ltr">
        Prin participarea la Concursuri, participanții, precum și orice persoană
        care va fi prezentă pe perioada desfășurării Concursurilor, își dau acordul
        pentru a fi înregistrați video și/sau audio și/sau foto și pentru a li se
        face publice fotografiile și/sau înregistrările video şi/sau audio pe site-ul
        <a href="http://itfest.sisc.ro">http://itfest.sisc.ro</a>, pe Instagram la
        <a href="https://www.instagram.com/sisc.ase/?hl=ro">@sisc.ase</a>, pe
        paginile de Facebook
        <a href="https://www.facebook.com/it.fest.sisc">
            ITFest
        </a>
        și <a href="https://www.facebook.com/sisc.ase">SiSC ASE</a>.
    </p>
    <p dir="ltr">
        Prin participarea la Concursuri, participanții precum și orice persoană care
        va fi prezentă pe perioada desfășurării concursurilor înțeleg să nu
        furnizeze public informații care pot aduce daune imaginii Organizatorului,
        sub sancțiunea suportării tuturor consecințelor generate de încălcarea
        acestei obligații. Prezenta obligație trebuie respectată pe durata
        Concursurilor, precum și după finalizarea sa.
    </p>
    <p dir="ltr">
        Participanții își vor aduce echipamentele de care au nevoie pentru
        dezvoltarea aplicației lor.
    </p>
    <p dir="ltr">
        Participanții care nu respectă prezentul Regulament și deciziile oficiale
        ale juriului, partenerilor și Organizatorului, vor fi excluși automat din
        concurs.
    </p>
    <br/>
    <ol start="5">
        <li dir="ltr">
            <h1 dir="ltr">
                Înscrierea și selectarea participanților
            </h1>
        </li>
    </ol>
    <p dir="ltr">
        Pentru a putea participa la unul dintre Maratoanele de programare,
        participantul trebuie să se înscrie completând formularul de înregistrare
        aflat pe site-ul <a href="https://itfester.sisc.ro/" target="blank">https://itfester.sisc.ro/</a>  și să își încarce CV-ul în cadrul
        formularului.
    </p>
    <p dir="ltr">
        Nu se percep taxe de înscriere.
    </p>
    <p dir="ltr">
        Înscrierea participanților se va face în perioada 8-14 octombrie și 22-28
        octombrie. După această dată nu se vor mai accepta alte înscrieri - excepție
        face situația în care Organizatorul decide să aibă loc prelungiri ale
        înscrierilor. Participanții au obligația să furnizeze informații reale și
        complete în formularul de înscriere în concurs.
    </p>
    <p dir="ltr">
        Participanții se pot înscrie doar la una dintre categoriile stabilite de
        organizator.
    </p>
    <p dir="ltr">
        Participanții se pot înscrie în echipe formate din 1-3 membri. Membrii
        acesteia își vor alege un reprezentant care va completa primul formularul de
        înscriere. Ulterior, este necesară completarea formularului de înscriere și
        de către ceilalți membri ai echipei, pe baza codului token primit de către
        reprezentant la înscrierea acestuia.
    </p>
    <p dir="ltr">
        Prin înscriere participantul declară expres că a citit, a înțeles și este de
        acord cu termenii și condițiile prezentului Regulament.
    </p>
    <p dir="ltr">
        Imposibilitatea de a participa la oricare dintre evenimentele desemnate va
        duce la descalificarea automată.
    </p>
    <br/>
    <ol start="6">
        <li dir="ltr">
            <h1 dir="ltr">
                Confidențialitatea datelor
            </h1>
        </li>
    </ol>
    <p dir="ltr">
        Organizatorul respectă și protejează confidențialitatea datelor personale
        din formularele de înscriere. Orice informație personală este prelucrată și
        folosită conform politicii de confidențialitate a datelor personale și nu va
        fi folosită în niciun mod care contravine acestei politici.
    </p>
    <p dir="ltr">
        Organizatorul are obligația de a nu folosi materialele trimise de
        participanți în alte scopuri ce nu țin de concurs. Totodată, organizatorul
        își rezervă dreptul de a oferi partenerilor CV-urile participanților.
    </p>
    <br/>
    <ol start="7">
        <li dir="ltr">
            <h1 dir="ltr">
                Dreptul de autor
            </h1>
        </li>
    </ol>
    <p dir="ltr">
        Prezentările lucrărilor sau orice alte materiale realizate în vederea
        concursului nu vor rămâne în proprietatea organizatorului, dar ne rezervăm
        dreptul de a ceda codurile sursă ale participanților în cazul în care
        acestea sunt solicitate de către partenerii proiectului.
    </p>
    <p dir="ltr">
        Aplicațiile dezvoltate de fiecare participant nu pot fi
        utilizate/comercializate de către participanți sau de către companiile
        partenere, fără acordul scris al fiecărei părți implicate .
    </p>
    <p dir="ltr">
        Organizatorul nu își asumă nicio răspundere în cazul în care lucrările
        realizate în urma concursului folosesc elemente de conținut (texte, imagini,
        coduri sursă etc.) care încalcă drepturile de autor. Întreaga răspundere
        aparține celui care a realizat lucrarea.
    </p>
    <br/>
    <ol start="8">
        <li dir="ltr">
            <h1 dir="ltr">
                Jurizarea
            </h1>
        </li>
    </ol>
    <p dir="ltr">
        Participanții au dreptul de a fi jurizați în mod echitabil, în aceleași
        condiții și după aceleași criterii în funcție de fiecare categorie.
    </p>
    <p dir="ltr">
        Juriul este format din reprezentanții companiilor partenere. Criteriile de
        jurizare sunt alese exclusiv de aceștia.
    </p>
    <p dir="ltr">
        Ierarhia se stabilește în ordinea descrescătoare a punctajelor obținute.
    </p>
    <p dir="ltr">
        Membrii juriului nu sunt obligați să acorde premii la o secțiune, în cazul
        în care lucrările din secțiunea respectivă nu sunt la un nivel calitativ
        satisfăcător.
    </p>
    <p dir="ltr">
        După ce concursul s-a încheiat, iar rezultatele au fost făcute publice,
        participanții pot depune plângeri sau contestații, după cum urmează:
    </p>
    <br/>
    <p dir="ltr">
        Imediat după finalizarea concursului concurenții pot depune o plângere
        organizatorilor, urmând ca aceștia să ia o decizie finală.
    </p>
    <br/>
    <p dir="ltr">
        Acest proces este guvernat după cum urmează:
    </p>
    <ul>
        <li dir="ltr">
            <p dir="ltr">
                Rezultatele concursului nu sunt finale, până când procesul de
                reclamații și contestații nu s-a finalizat.
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Orice altă reclamație trebuie să se bazeze pe una sau mai multe din
                următoarele situații:  încălcări ale regulamentului, abaterea unei
                echipe sau abateri ale unor oficiali ai concursului cu intenția de a
                frauda.
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Doar participanții pot depune reclamații și contestații.
            </p>
        </li>
        <li dir="ltr">
            <p dir="ltr">
                Deciziile sunt definitive. Mai exact, o decizie privind rezolvarea
                unei probleme nu poate fi contestată.
            </p>
        </li>
    </ul>
    <br/>
    <p dir="ltr">
        Recursul va fi respins în mod automat în cazul în care procedura de mai sus
        nu este urmată.
    </p>
    <br/>
    <ol start="9">
        <li dir="ltr">
            <h1 dir="ltr">
                Premii acordate
            </h1>
        </li>
    </ol>
    <p dir="ltr">
        În cadrul Concursului, organizatorul oferă câte 3 (trei) premii pentru
        fiecare categorie. Premiile reprezintă vouchere în valoare de 2400 lei
        (locul I), 2100 lei (locul II), respectiv 1800 lei (premiul III).
    </p>
    <p dir="ltr">
        Participanții câștigători vor fi contactați ulterior de către Organizator
        pentru a intra în posesia premiilor.
    </p>
    <p dir="ltr">
        Organizatorul nu va fi ținut răspunzător pentru toate și oricare prejudicii,
        suferite de către câștigător, indiferent de natura acestor prejudicii,
        suferite din momentul predării premiului către câștigător.
    </p>
    <br/>
    <ol start="10">
        <li dir="ltr">
            <h1 dir="ltr">
                Litigii
            </h1>
        </li>
    </ol>
    <p dir="ltr">
        Eventualele litigii apărute între organizator și concurenți se vor rezolva
        pe cale amiabilă.
    </p>
    <p dir="ltr">
        În cazul în care un concurent are de semnalat un incident în legătură cu
        desfășurarea evenimentului, se va adresa organizatorului cu o sesizare
        scrisă în acest sens trimisă pe adresa itfest@sisc.ro, în termen de maximum
        15 de zile de la data incidentului, organizatorul fiind obligat să ofere un
        răspuns în maximum 30 de zile.
    </p>
    <br/>
    <ol start="11">
        <li dir="ltr">
            <h1 dir="ltr">
                Anexă
            </h1>
        </li>
    </ol>
    <a href="https://docs.google.com/document/d/1lMSTUEKfz35W7JHCxWJ5o949H47sxZQQ6RUluOlD9V8/edit?usp=sharing" target="_blank" dir="ltr">
        <u>Descarcă declarația pe propria răspundere</u>
    </a>
    <br/>
    <br/>
    <br/>
    <br/>
  </div>
);
