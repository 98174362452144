import React from "react";

import styles from "./GiveawayPage.module.css";
import Description from "../../../components/description/Description";
import GiveawayStep from "../../../components/giveawayStep/GiveawayStep";
import useStore from "../../../store/userStore";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../../../components/form/FormInput";
import { Button } from "@mui/material";
import Axios from "axios";
import { APIURL } from "../../../constants";
import { Link } from "react-router-dom";

const giveawayText = (
  <div className={styles.giveawayInfoContainer}>
    <div>
      <h2>Giveaway-ul va Începe în curând</h2>
      {/* <p className={styles.cardText}>
        - <span className={styles.numberOfCards}>x3</span> cursuri în domeniul
        IT de la{" "}
        <Link
          to="https://4mayo.ro/"
          target="_blank"
          style={{
            textDecoration: "underline",
          }}
        >
          4mayo
        </Link>{" "}
        în valoare totală de 1500 euro
      </p> */}
    </div>

    {/* <img
      src="./icons/4mayo-icon.png"
      alt="4mayo-course"
      className={styles.giveawayImage}
    /> */}
  </div>
);

const giveawaySteps = [
  {
    step: 1,
    text: "Înregistreză-te cu un CV valid pe ITFester",
  },
  {
    step: 2,
    text: "Distribuie postarea cu toate evenimentele ITFest pe Instagram story",
  },
  {
    step: 3,
    text: "Dă tag la 3 prieteni în cadrul postării cu toate evenimentele ITFest pe Instagram",
  },
  {
    step: 4,
    text: "Urmărește pagina de Instagram",
    link: {
      href: "https://www.instagram.com/sisc.ase/",
      text: "@sisc.ase",
    },
  },
  {
    step: 5,
    text: "Urmărește pagina de Instagram",
    link: {
      href: "https://www.instagram.com/_4mayo_/",
      text: "@4mayo",
    },
  },
  {
    step: 6,
    text: "(opțional) Apreciază pagina de Facebook ITFest și completează",
    link: {
      href: "https://docs.google.com/forms/d/e/1FAIpQLSdzqSrmj_qcG76vys9TdGn8mDmVYVTGn2oBqnmQJ-e9kDAXeg/viewform?fbclid=IwAR0Y1zFjvDp2wcGbbfqGbcyNPsKyEAEa1_nu_5PNzcfrsHrLTraekFaOBL4",
      text: "formularul de nevoi",
    },
  },
];

function GiveawayPage() {
  const [user, setUser] = useStore((state) => [state.user, state.setUser]);

  const methods = useForm({
    defaultValues: {
      instagramLink: "",
      facebookLink: "",
    },
  });

  function onSubmit(formData) {
    const updatedUser = {
      ...user,
      instagramLink: formData.instagramLink,
      facebookLink: formData.facebookLink,
    };
    console.log(updatedUser);
    Axios.put(`${APIURL}/user/${user.id}`, updatedUser)
    .then((response) => {
      console.log(response, "response");
      setUser(response.data);
    }).catch((error) => {
      console.log(error);
    });
  }

  return (
    <div>
      <h1>{giveawayText}</h1>
    {/* De decomentat atunci cand avem giveaway */}
      {/* <div className={styles.giveawayStepsContainer}>
        {giveawaySteps.map((giveaway) => {
          return <GiveawayStep key={giveaway.step} giveaway={giveaway} />;
        })}
      </div> */}

      {/* <div className={styles.formContainer}>
        {(user.facebookLink.localeCompare("https://www.facebook.com") !== 0 &&
        user.instagramLink.localeCompare("https://www.instagram.com") !== 0) ? (
          <p className={styles.text}>Te-ai înscris la giveaway</p>
        ) : (
          <FormProvider {...methods}>
            <form
              className={styles.form}
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <FormInput
                inputName="instagramLink"
                rules={{
                  required: "Link-ul de Instagram este obligatoriu",
                  pattern: {
                    value:
                      /^https:\/\/www\.instagram\.com\/[a-zA-Z0-9._]{1,30}\/$/,
                    message: "Link-ul de Instagram nu este valid",
                  },
                }}
                placeholderText="Link Instagram"
              />

              <FormInput
                inputName="facebookLink"
                rules={{
                  required: "Link-ul de Facebook este obligatoriu",
                  pattern: {
                    value:
                      /^(https:\/\/www\.facebook\.com\/[a-zA-Z0-9.]+|https:\/\/www\.facebook\.com\/profile\.php\?id=\d+)$/,
                    message: "Link-ul de Facebook nu este valid",
                  },
                }}
                placeholderText="Link Facebook"
              />

              <Button
                type="submit"
                fullWidth
                sx={{
                  color: "white",
                  backgroundColor: "#1A9A32",
                  borderRadius: "1.5rem",
                  "&:hover": {
                    backgroundColor: "#136B1E",
                  },
                }}
              >
                Înscrie-te
              </Button>
            </form>
          </FormProvider>
        )}
      </div> */}
    </div>
  );
}

export default GiveawayPage;
