import React, { useRef, useState } from "react";

import { Alert, Button, Slide, Snackbar } from "@mui/material";
import { Link } from "react-router-dom";

function ToTechnicalTest({ testLink, companyName, period, disable }) {
  // const currentDate = new Date()
  // const disabledStatus = (disable || period[0] > currentDate || period[1] < currentDate) ? true : false
  const [openToast, setOpenToast] = useState(false);
  const toastObj = useRef({
    type: "info",
    message: "",
  });
  return (
    <>
      <Button
        variant="contained"
        // disabled={disabledStatus}
        onClick={() => {
          if (disable) {
            toastObj.current.type = "info";
            toastObj.current.message =
              "Pentru a te înscrie la Career Launch Adobe trebuie doar să ai cv-ul înregistrat în aplicație.";
            setOpenToast(true);
          }
        }}
        sx={{
          color: "var(--black)",
          backgroundColor: "#FFAD4A",
          borderRadius: "1.5rem",
          "&:hover": {
            backgroundColor: "#f99b28",
          },
          "&.Mui-disabled": {
            backgroundColor: "lightgray",
          },
        }}
      >
        {!disable ? (
          <Link
            to={testLink}
            target="_blank"
            style={{ color: "black", fontFamily: "inherit" }}
          >
            Test tehnic {companyName.slice(0, 3)}.
          </Link>
        ) : (
          "Info inscriere"
        )}
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        TransitionComponent={Slide}
      >
        <Alert severity={toastObj.current.type}>
          {toastObj.current.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ToTechnicalTest;
