import React from 'react';
import styles from "./TeamMember.module.css";

const TeamMember = ({ id, name, isTeamCaptain, onDelete }) => {

    const stergeMembru = () => {
        onDelete(id);
    };

    return (
        <div className={styles.membru}>
            <div>{name}</div>
            {isTeamCaptain ? <div className={styles.close} onClick={stergeMembru}>&#x2715;</div> : null}
        </div>
    );
}

export default TeamMember;
