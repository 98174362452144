import styles from "./ResetPasswordContainer.module.css";

function ForgotPasswordContainer({ children }) {
  return (
    <>
      <div className={styles.passwordContainerBorder}>
        <div className={styles.passwordContainer}>
          <div className={styles.passwordElements}>
            <div className={styles.title}>Resetează parola</div>
            <p>
              Folosește o parolă sigură.
            </p>
          </div>
          {children}
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordContainer;
