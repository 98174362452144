import React, { useRef, useState } from "react";
import styles from "./WorkshopPage.module.css";
import { workshopDescription } from "../../../staticData/general/workshopDescription";
import { workshopCompanyData } from "../../../staticData/workshop/workshopData";
import CompanyCard from "../../../components/card/CompanyCard";
import { Alert, Button, Slide, Snackbar } from "@mui/material";
import useStore from "../../../store/userStore";
import Axios from "axios";
import { APIURL } from "../../../constants";

const workshopID = 3;
const workshopEnd = new Date("2024-10-30T00:00:00");

function WorkshopPage() {
  const [user, eventsAttended, addEventAttended] = useStore((state) => [
    state.user,
    state.eventsAttended,
    state.addEventAttended,
  ]);

  const [openToast, setOpenToast] = useState(false);
  const toastObj = useRef({
    type: "error",
    message: "",
  });

  function workshopSignUp() {
    Axios.post(`${APIURL}/attendance/${user.id}`, {
      eventId: workshopID,
    })
      .then((res) => {
        toastObj.current.type = "success";
        toastObj.current.message = res.data;
        setOpenToast(true);
        addEventAttended(workshopID);
      })
      .catch((error) => {
        toastObj.current.message = "Eroare de server";
        console.log(error);
        setOpenToast(true);
      });
  }

  return (
    <div>
      <h1 className={styles.title}>Workshop</h1>
      <div className={styles.workshopPageContainer}>
         {/* Container descriere */}
        <div className={styles.eventsDescriere}>{workshopDescription}</div>
        {/* Container chestie alba */}
        <div className={styles.workshopContainer}>
          <h2 className={styles.workshopDate}>30 octombrie</h2>
          <CompanyCard eventData={workshopCompanyData} />
          {new Date() < workshopEnd &&
            (eventsAttended[workshopID] ? (
              <p className={styles.signedUp}>Ai fost înscris că participi la eveniment </p>
            ) : (
              <Button
                onClick={workshopSignUp}
                variant="contained"
                sx={{
                  backgroundColor: "#1A9A32",
                  borderRadius: "1.5rem",
                  "&:hover": {
                    backgroundColor: "#136B1E",
                  },
                }}
              >
                Sunt interesat
              </Button>
            ))}
        </div>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openToast}
          autoHideDuration={3000}
          onClose={() => setOpenToast(false)}
          TransitionComponent={Slide}
        >
          <Alert severity={toastObj.current.type}>
            {toastObj.current.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default WorkshopPage;
