import {
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { Route } from "react-router-dom";
import App from "../App";
import RoleBasedAccess from "./RoleBasedAccess";

import Sidebar from "../components/sidebar/Sidebar";

import LogInPage from "../pages/user-pages/log-in/LogInPage";
import SignUpPage from "../pages/user-pages/sign-up/SignUpPage";
import ProfilePage from "../pages/user-pages/profile/ProfilePage";
import HackathonPage from "../pages/user-pages/hackathon/HackathonPage";
import CareerLaunchPage from "../pages/user-pages/career-launch/CareerLaunchPage";
import AchievementPage from "../pages/user-pages/achievements/AchievementPage";
import GiveawayPage from "../pages/user-pages/giveaway/GiveawayPage";
import JobHubPage from "../pages/user-pages/job-hub/JobHubPage";
import WorkshopPage from "../pages/user-pages/workshop/WorkshopPage";
import DashboardPage from "../pages/admin-pages/dashboard/DashboardPage";
import QRPage from "../pages/admin-pages/qr-code/QRPage";
import LanParty from "../pages/user-pages/lan-party/LanParty";
import AdminSidebar from "../components/sidebar/AdminSidebar";
import ForgotPassword
 from "../pages/user-pages/forgot-password/ForgotPassword";
import ResetPassword from "../pages/user-pages/reset-password/ResetPassword";
import { ScrollRestoration } from "react-router-dom";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<App />}>
        {/* rute user neautentificat */}
        <Route path="/" element={<LogInPage />} />
        <Route path="sign-up" element={<SignUpPage />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:resetToken" element={<ResetPassword />} />
        {/* rute user */}
        <Route element={<RoleBasedAccess WrapperComponent={Sidebar} />}>
          <Route path="profile" element={<ProfilePage />} />
          <Route path="job-hub" element={<JobHubPage />} />
          <Route path="career-launch" element={<CareerLaunchPage />} />
          <Route path="workshop" element={<WorkshopPage />} />
          <Route path="hackathon" element={<HackathonPage />} />
          <Route path="giveaway" element={<GiveawayPage />} />
          <Route path="achievements" element={<AchievementPage />} />
          <Route path="lan-party" element={<LanParty />} />
        </Route>

        {/* rute admin */}
        <Route element={<RoleBasedAccess WrapperComponent={AdminSidebar} />}>
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="users-data" element="Pagina users data" />
          <Route path="qr-code" element={<QRPage />} />
        </Route>
      </Route>
      
    </>
  )
);
