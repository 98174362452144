import React, { useEffect, useState } from "react";
import styles from "./Echipe.module.css";
import useStore from "../../../store/userStore";
import Axios from "axios";
import { APIURL } from "../../../constants";
import { hackathonAdobe } from "../../../staticData/hackathons/adobeHackathon";
import { hackathonNable } from "../../../staticData/hackathons/n-ableHackathon";

const hackathons = [hackathonAdobe, hackathonNable];

function Echipe() {
  const [teams, setTeams] = useState([]);
  const signedUpHackathonID = useStore((state) => state.signedHackathon);
  useEffect(() => {
    if (signedUpHackathonID !== 0) {
      Axios.get(`${APIURL}/hackathon/getAllTeams/${signedUpHackathonID}`, {
        withCredentials: true,
      })
        .then((res) => {
          console.log(res.data);
          setTeams(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [signedUpHackathonID]);
  console.log(teams);
  return (
    <div className={styles.chenarEchipe}>
      {signedUpHackathonID === 4 && <div className={styles.titlu}>Echipe înscrise la Adobe</div>}
      {signedUpHackathonID === 5 && <div className={styles.titlu}>Echipe înscrise la N-able</div>}
      <div className={styles.continutEchipe}>
        {signedUpHackathonID &&
          teams.map((elem) => {
            return (
              <div key={elem.id} className={styles.echipa}>
                <div className={styles.teamName}>
                  <p className={styles.teamNameWrapper}>{elem.name}</p>
                </div>
                <ol>
                  {elem.members.map((member, index) => {
                    return (
                      <li key={member.id} className={styles.text}>
                        {index + 1}.{member.name}
                      </li>
                    );
                  })}
                </ol>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Echipe;
