import React from "react";
import useStore from "../store/userStore";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function RoleBasedAccess({ WrapperComponent }) {
  const [user] = useStore((state) => [state.user]);
  const navigate = useNavigate();
  useEffect(() => {
    if (user === null) {
      navigate("/");
    } else if (user.isAdmin !== true) {
      navigate("profile");
    } else {
      navigate("dashboard");
    }
  }, [user]);

  if (user !== null) {
    return (
      <WrapperComponent>
        <Outlet />
      </WrapperComponent>
    );
  }
}

export default RoleBasedAccess;
