import React, { useCallback, useRef, useState } from 'react'

import styles from "./AboutQuest.module.css"
import { Button } from '@mui/material'
import CustomModal from '../modal/CustomModal'

function AboutQuestButton({ title, aboutQuest }) {
  const openModalRef = useRef()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  return (
    <div>
      <Button
      onClick={openModal}
      variant="contained" 
      sx={{
        width: "130px",
        backgroundColor: "#074A29",
        borderRadius: "1.5rem",
        '&:hover': {
          backgroundColor: "#0E7E42",
        },
        
      }}>Despre Quest</Button>

      <CustomModal onClose={closeModal} isOpen={isModalOpen} title={title}>
        <p className={styles.AboutQuestContent}>{aboutQuest}</p>
      </CustomModal>
    </div>
  )
}

export default AboutQuestButton