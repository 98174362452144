import React, { useCallback, useState } from "react";
import style from "./LanPartyStyles.module.css";
import CustomModal from "../modal/CustomModal";
import { fifa } from "../../staticData/lanParty/fifa";

const lanPartySignUpDate = new Date("2024-11-02T00:00:00");

const LanPartyJocuri = ({ games, onNext }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  let imgStyle = style.imgLan;
  // let btnStyle = style.buttonFormLanParty;
  let btnStyle = style.buttonGreyedFormLanParty;
  let btnText = "Next";
  let disabledbtn = false;

  return (
    <div className={style.gameContainer}>
      {
        new Date() < lanPartySignUpDate ? (

          games.map((game) => {
            return (
              <div key={game.name} className={style.gameCard}>
                <img src={game.img} className={imgStyle} alt="" />
                <button
                  onClick={() => onNext(game)}
                  className={btnStyle}
                  disabled={true}
                >
                  {btnText}
                </button>
              </div>
            );
            }
          ) 
        ) : (
          games.map((game) => {
            if (game.ales === true) {
              imgStyle = style.imgLanAles;
              btnStyle = style.buttonFormLanParty;
              disabledbtn = false;
              btnText = "Info";
            } else if (game.ales === false) {
              imgStyle = style.imgLanNeAles;
              disabledbtn = true;
              btnStyle = style.buttonGreyedFormLanParty;
              btnText = "Not selected";
            }
  
            if (game.id !== 8) {
              return (
                <div key={game.name} className={style.gameCard}>
                  <img src={game.img} className={imgStyle} alt="" />
                  <button
                    onClick={() => onNext(game)}
                    className={btnStyle}
                    disabled={disabledbtn}
                  >
                    {btnText}
                  </button>
                </div>
              );
            } else {
              return (
                <div key={game.name} className={style.gameCard}>
                  <img src={game.img} className={imgStyle} alt="" />
                  <button
                    onClick={openModal}
                    className={btnStyle}
                    disabled={disabledbtn}
                  >
                    {btnText}
                  </button>
                  <CustomModal
                    onClose={closeModal}
                    isOpen={isModalOpen}
                    title={fifa.rules.title}
                  >
                    {fifa.rules.text}
                </CustomModal>
                </div>
                
              );
            }
          })
        )
      }
    
    </div>
  );
};

export default LanPartyJocuri;
