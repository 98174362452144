import { create } from "zustand";

const useStore = create((set) => ({
  user: null,
  eventsAttended: {},
  teamHackathonId: 0,
  signedHackathon: 0,
  isCaptain: false,
  setUser: (user) => {
    set({ user });
  },
  setEventsAttended: (eventsAttended) => {
    set({ eventsAttended });
  },
  addEventAttended: (eventID) => {
    set((state) => ({
      eventsAttended: { ...state.eventsAttended, [eventID]: true },
    }));
  },
  setSignedUpHackathonID: (signedHackathon) => {
    set({signedHackathon});
  },
  setTeamHackathonId: (teamHackathonId) => {
    set({teamHackathonId});
  },
  setCaptain: (isCaptain) => {
    set({isCaptain});
  }
}));

export default useStore;
